<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>

<section class="pt-4">
    <div class="container center pt-1">
        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
        <h3 class="display-4 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
            Découvre comment passer de<span class="color-important-word"> {{ getResponseTitleMen(session.step3,
                session.step3.response) }} </span>sur internet en <span class="color-important-word">30 jours</span>
            grâce à ton business.
        </h3>
        <!-- Contenu visible uniquement sur les smartphones -->
        <h3 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
            Découvre comment passer de<span class="color-important-word"> {{ getResponseTitleMen(session.step3,
                session.step3.response) }} </span>sur internet en <span class="color-important-word">30 jours</span>
            grâce à ton business.
        </h3>
    </div>
</section>

<!-- Section video -->
<section class="video-vsl">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="clear"></div>
                <div class="text-center">
                    <div class="wistia_responsive_padding mb-5" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper"
                            style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_sb92z7iycd seo=true videoFoam=true"
                                style="height:100%;position:relative;width:100%">
                                <div class="wistia_swatch"
                                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                    <img src="https://fast.wistia.com/embed/medias/sb92z7iycd/swatch"
                                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                        aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="d-none d-md-block">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill "></i>OBTENIR LA STRATEGIE</a>
                    </div>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class=" d-block d-md-none">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-mini button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill"></i>OBTENIR LA STRATEGIE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="douleur-section">
    <div class="content-wrap">
        <!-- <div class="page-section"> -->
        <div class="container ">
            <div class="text-center mb-4">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                    300 millions d'emplois pourraient être transformés ou remplacés !<br> La vraie question est :
                    que
                    vas-tu faire ?
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none douleur-section-text-title">
                    300 millions d'emplois pourraient être transformés ou remplacés !<br> La vraie question est :
                    que
                    vas-tu faire ?
                </h6>
            </div>
        </div>
        <div class="container container-sam">

            <div class="text-center">

                <img src="assets/images/simulator/sam-altman.png">
                <p>Sam Altman, le CEO d’OpenAI, annonce l’arrivée d’une superintelligence dans 1 000 jours, qui
                    pourrait bouleverser le monde, tout comme l’intelligence artificielle l’a fait. Des millions de
                    personnes risquent de perdre leur emploi. Ce n’est pas juste une supposition, les faits sont là.
                    Mais ici, nous te proposons une solution pour sécuriser ton avenir et générer de nouvelles
                    sources de revenus.
                </p>
            </div>
        </div>

        <div class="text-center">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <div class="d-none d-md-block pt-4" style="margin-bottom: -45px;">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill "></i>OBTENIR LA STRATEGIE</a>
            </div>
            <!-- Contenu visible uniquement sur les smartphones -->
            <div class="d-block d-md-none pt-5" style="margin-bottom: -50px;">
                <a (click)="scrollToElement('section-pricing')"
                    class="button button-mini button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>OBTENIR LA STRATEGIE</a>
            </div>
        </div>
        <!-- </div> -->
    </div>
</section>

<section style="background-color: #000000;">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block text-white mb-5">

                        Fais tes pronostics, regarde comme il est simple de faire de l'argent !
                    </h6>
                    <span class="fw-bolder text-white d-none d-md-block pt-3">Tu vends {{ dailyCustomers }} <span
                            *ngIf="dailyCustomers === 1">produit</span> <span
                            *ngIf="dailyCustomers !== 1">produits</span> par
                        jour x {{ productPrice | currency }} x 30
                        jours = {{ projectedRevenue | currency:'EUR':'symbol':'1.0-0':'fr' }}</span>

                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none text-white">
                        Fais tes pronostics, regarde comme il est simple de faire de l'argent !
                    </h6>
                    <span class="fw-bolder text-white d-block d-md-none pt-3">Tu vends {{ dailyCustomers }} <span
                        *ngIf="dailyCustomers === 1">produit</span> <span
                        *ngIf="dailyCustomers !== 1">produits</span> par
                        jour x {{ productPrice | currency }} x 30
                        jours = {{ projectedRevenue | currency:'EUR':'symbol':'1.0-0':'fr' }}</span>

                </div>
                <div class="slider-container pt-5">
                    <h3 class="text-white fw-bolder">Revenu mensuel </h3>
                    <!-- <h2 class="text-white">{{ projectedRevenue | currency:'EUR' }}</h2> -->
                    <h2 class="text-white fw-bolder projectRevenue " style="margin-top: -30px;">{{ projectedRevenue |
                        currency:'EUR':'symbol':'1.0-0':'fr' }}</h2>


                    <div class="slider">
                        <label for="price text-white fw-bolder">Prix de vente du produit</label>
                        <input type="range" id="price" [(ngModel)]="productPrice" (input)="updateRevenue()" min="10"
                            max="500" step="1" />
                        <span class="span-daily-customer fw-bolder">{{ productPrice | currency }}</span>
                    </div>

                    <div class="slider pt-4">
                        <label for="daily-customers fw-bolder">Nombre de vente par jour</label>
                        <input type="range" id="daily-customers" [(ngModel)]="dailyCustomers" (input)="updateRevenue()"
                            min="1" max="100" step="1" />
                        <span class="span-daily-customer fw-bolder">{{ dailyCustomers }}</span>
                    </div>
                </div>
                <div class="text-center pt-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="d-none d-md-block">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill "></i>OBTENIR LA STRATEGIE</a>
                    </div>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class="d-block d-md-none" style="margin-bottom: -35px;">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-mini button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill"></i>OBTENIR LA STRATEGIE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="mockup-section">
    <div class="content-wrap">
        <div class="container">

            <div class="text-center mb-6">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                    Atteins tes objectifs et transforme ton business avec l'IA
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                    Atteins tes objectifs et transforme ton business avec l'IA
                </h6>
            </div>

            <div class="row gallery-categories gutter-20 mockup-section-gallery">
                <div class="col-md-12">
                    <div
                        style="background: url('assets/images/simulator/mockup/1.png') no-repeat center center; background-size: cover; height: 450px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/simulator/mockup/3.png') no-repeat center center; background-size: cover; height: 250px;">
                    </div>
                </div>

                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/simulator/mockup/4.png') no-repeat 50% 40%; background-size: cover; height: 250px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/simulator/mockup/5.png') no-repeat 100% 100%; background-size: cover; height: 250px;">
                    </div>
                </div>
            </div>

        </div>

    </div>
</section>


<section class="section-plan-action">
    <div class="container">
        <div class="text-center">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">

                <span class="color-important-word">{{ getResponseTitleMen(session.step3, session.step3.response)
                    }}</span> en 30 jours
            </h6>
            <!-- Contenu visible uniquement sur les smartphones -->
            <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">

                <span class="color-important-word">{{ getResponseTitleMen(session.step3, session.step3.response)
                    }}</span> en 30 jours
            </h6>
            <div>
                <img width="320px" src="assets/images/simulator/plandactionfelche.jpg">
            </div>
        </div>


        <div class="scroll-container mb-5">
            <div class="scroll-track" #scrollTrack>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 1</span>
                            <p>Identifier les tendances
                                et choisir une niche
                                prometteuse</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 2</span>
                            <p>Comprendre les
                                questions et
                                préoccupations des
                                clients potentiels</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 3</span>
                            <p>Analyser la concurrence
                                et identifier des mots-
                                clés pertinents</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 4</span>
                            <p>Recueillir des données
                                sur les besoins des
                                clients potentiels</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 5</span>
                            <p>Conduire une étude de
                                marché pour recueillir
                                des données
                                quantitatives et
                                qualitatives</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 6</span>
                            <p>Créer des formulaires
                                interactifs pour collecter
                                des informations
                                détaillées</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 7</span>
                            <p>Analyser les
                                comportements des
                                utilisateurs sur le site</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10" style="background-color: #027d00;">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder text-white">Bonus</span>
                            <p class="text-white">Générer des<br> idées de
                                business</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 8</span>
                            <p>Créer un logo<br> professionnel</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 9</span>
                            <p>Développer une palette de
                                couleurs et des polices de
                                marque</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 10</span>
                            <p>Concevoir des visuels pour
                                les réseaux sociaux</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 11</span>
                            <p>Personnaliser le<br> contenu du
                                site web</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 12</span>
                            <p>Créer un <br>site web avec l’IA</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10" style="background-color: #027d00;">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder text-white">Bonus</span>
                            <p class="text-white">Bonus : Alternatives pour les
                                boutiques en ligne</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10 ">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 13</span>
                            <p>Optimiser ton site web pour
                                le référencement (SEO)</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 14</span>
                            <p>Générer des idées de
                                contenu et des scripts
                                conversationnels</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 15</span>
                            <p>Rédiger des articles de blog
                                engageants</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 16</span>
                            <p>Générer du contenu<br> de
                                haute qualité</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 17</span>
                            <p>Améliorer la qualité<br> du
                                contenu</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 18</span>
                            <p>Transformer du texte<br> en
                                vidéos</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 19</span>
                            <p>Créer des visuels<br> attrayants</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 20</span>
                            <p>Créer des images et des
                                illustrations avancées</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 21</span>
                            <p>Créer des graphiques et des
                                infographies</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 22</span>
                            <p>Générer du contenu<br> audio</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 23</span>
                            <p>Créer des animations et des
                                designs créatifs</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 24</span>
                            <p>Améliorer la qualité<br> de tes
                                audios</p>
                        </div>
                    </div>
                </div>

                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 25</span>
                            <p>Automatisation des<br> tâches
                                administratives</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 26</span>
                            <p>Création de <br>chatbot GPT</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 27</span>
                            <p>Gestion légale<br> automatisée</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 28</span>
                            <p>Rédaction d'e-mails<br> personnalisés</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 29</span>
                            <p>Gestion du <br>service client</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 30</span>
                            <p>Automatisation <br>des workflows</p>
                        </div>
                    </div>
                </div>
                <div class="scroll-item col-6 col-md-3 text-center align-items-stretch d-flex">
                    <div class="card scroll-card bg-opacity-10">
                        <div class="card-body p-4 pointer">
                            <span class="fw-bolder">Jour 31</span>
                            <p>Améliorer ses prompts</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="cestnow-section">
    <div class="content-wrap" style="background-color: #000000">
        <div class="container">
            <div class="text-center mb-5 cestnow-section-text-tile">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block text-white">
                    C'EST NOW!
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none text-white">
                    C'EST NOW!
                </h6>
            </div>


            <div class="single-post mb-0 mw-md mx-auto bg-light p-4 p-sm-5 rounded-1 pt-5">
                <div class="entry">
                    <div class="entry-content mt-3">

                        <p>Nous sommes des rêveurs, des bâtisseurs, des explorateurs de nouvelles possibilités. Mais
                            surtout, nous passons à l’action. En quelques années, grâce à des stratégies claires et
                            éprouvées, nous avons créé notre propre SaaS performant et aidé nos clients, comme vous, à
                            générer des revenus significatifs en optimisant la vente de leurs produits.</p>

                        <p>Ensemble, nous avons dépassé le million d’euros en résultats cumulés, prouvant qu’avec la
                            bonne méthode, vos idées peuvent elles aussi devenir un véritable succès.</p>

                        <p>Ce que vous tenez entre vos mains – ou ce que vous êtes sur le point de découvrir – n’est pas
                            un simple eBook. C’est une méthode, un guide, un tremplin. Ce sont des outils qui ont été
                            façonnés avec soin, testés sur le terrain et perfectionnés pour une raison : produire des
                            résultats concrets et mesurables.</p>
                        <div class="text-center">
                            <h6 class="display-6 fw-bolder ls1 mb-0 position-relative d-none d-md-block">
                                Applique toi aussi la stratégie
                            </h6>
                            <img class="cestnow-section-img-fleche" src="assets/images/simulator/fleche.gif">
                        </div>
                        <div class="row align-wide-xl mb-4 text-center">
                            <div class="cestnow-section-img-preuve-autorite">
                                <img src="assets/images/simulator/preuve-autorite.png">
                            </div>
                        </div>
                        <p>Nous savons que le chemin peut parfois sembler flou, complexe, ou même hors d’atteinte. C’est
                            précisément pour cela que nos méthodes existent. Parce qu’ensemble, nous avons le pouvoir de
                            clarifier l’incertain, de surmonter les obstacles et de bâtir des projets solides et
                            rentables.</p>



                        <p>Ici, nous ne parlons pas de solutions magiques ou de promesses vides. Nous parlons d’un
                            processus, d’une vision et d’une stratégie qui ont fait leurs preuves. Nous avons déjà
                            accompagné des dizaines (voire centaines) d’entrepreneurs dans leur quête de croissance,
                            avec des résultats tangibles et une transformation durable.</p>

                        <p>Et si aujourd’hui était le début de quelque chose de grand pour vous aussi ? Parce qu’au
                            fond, nous le savons tous : c’est possible. Nous l’avons fait. Nos clients l’ont fait. Et
                            vous le pouvez aussi. Alors, passe à l’action, c’est maintenant !</p>
                        <div class="row align-wide-xl mb-4 text-center">
                            <div>
                                <img src="assets/images/simulator/kajabi-hero.jpg" width="800px">
                            </div>
                        </div>
                        <div class="text-center">
                            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                            <div class="d-none d-md-block" style="margin-bottom: -90px;">
                                <a (click)="scrollToElement('section-pricing')"
                                    class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                        class="bi-arrow-down-right-circle-fill "></i>OBTENIR LA STRATEGIE</a>
                            </div>
                            <!-- Contenu visible uniquement sur les smartphones -->
                            <div class="d-block d-md-none" style="margin-bottom: -90px;">

                                <a (click)="scrollToElement('section-pricing')"
                                    class="button button-mini button-circle button-green fw-bolder call-to-action-background-color"><i
                                        class="bi-arrow-down-right-circle-fill"></i>OBTENIR LA STRATEGIE</a>
                            </div>
                        </div>
                    </div>
                </div><!-- .entry end -->
            </div>
        </div>
    </div>
</section>





<section>
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Un ecommerce ou une prestation de service ?
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Un ecommerce ou une prestation de service ?
                    </h6>
                    <p>Ce guide est applicable à tous les types de business. Tu y découvriras des exemples concrets et
                        des plans d’action spécifiques.</p>

                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5 pt-4">
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="justify-content-center grid-inner bg-contrast-0 border border-default p-5 rounded-6 h-100 d-flex flex-column all-ts h-shadow-sm h-translate-y"
                            data-class="dark:bg-contrast-100">
                            <div class="d-flex fs-1 color mb-3 justify-content-center">
                                <!-- <i class="bi-magic"></i> -->
                                <img src="assets/images/simulator/e-commerce.png" width="80px" class="center">
                            </div>
                            <h3 class="fs-5 mb-2 fw-bolder">E-commerce</h3>
                            <p class="mb-4 fw-light">Booste tes ventes en ligne et transforme ton e-commerce en machine
                                à profit.</p>
                            <div class="bg-icon bi-layout-wtf color"
                                style="opacity:0.05;bottom:-85px;right:-70px;z-index:-1"></div>
                        </div>
                    </div>
                    <div class="col-md-6 col-lg-4 col-xl-3">
                        <div class="grid-inner bg-contrast-0 border border-default p-5 rounded-6 h-100 d-flex flex-column all-ts h-shadow-sm h-translate-y"
                            data-class="dark:bg-contrast-100">
                            <div class="d-flex fs-1 color mb-3 justify-content-center">
                                <!-- <i class="bi-magic"></i> -->
                                <img src="assets/images/simulator/freelance.png" width="80px" class="center">
                            </div>
                            <h3 class="fs-5 mb-2 fw-bolder">Un service en ligne</h3>
                            <p class="mb-4 fw-light">Structure ton offre et attire des clients prêts à payer pour ton
                                expertise.</p>
                            <div class="bg-icon bi-layout-wtf color"
                                style="opacity:0.05;bottom:-85px;right:-70px;z-index:-1"></div>
                        </div>
                    </div>

                </div>
                <div class="text-center">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <div class="d-none d-md-block">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-xlarge button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill "></i>OBTENIR LA STRATEGIE</a>
                    </div>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <div class="d-block d-md-none">
                        <a (click)="scrollToElement('section-pricing')"
                            class="button button-mini button-circle button-green fw-bolder call-to-action-background-color"><i
                                class="bi-arrow-down-right-circle-fill"></i>OBTENIR LA STRATEGIE</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section style="background-color: #FFF8E0;">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        +250 outils IA à ton service
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        +250 outils IA à ton service
                    </h6>
                    <p>L’extrême technologie : intelligence artificielle avec ChatGPT, MidJourney et les centaines de
                        plateformes qui émergent pour remplacer des millions d’emplois dans le monde …</p>
                </div>

                <div class="scroll-container pt-2">
                    <div class="scroll-track" #scrollTrack>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-01.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-02.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-03.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-04.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-05.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-06.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-07.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-08.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-09.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-10.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-12.png" alt="Investor Image" />
                                </div>
                            </div>
                        </div>
                        <div class="scroll-item-img col-6 col-md-3 text-center align-items-stretch d-flex">
                            <div class="card scroll-card-ia bg-opacity-10">
                                <div class="card-body p-4 pointer">
                                    <img src="assets/images/simulator/ia/ia-icon-13.png" alt="Investor Image"
                                        width="54px" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="programme-section">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Pensé pour ton succès financier !
                        <br />Voici ce qui t’attend :
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Pensé pour ton succès financier !
                        <br />Voici ce qui t’attend :
                    </h6>
                </div>
                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #000000;"
                            data-bs-theme="dark">
                            <h3 class="mb-3 display-6 fw-bold"><b>Une newsletter mensuelle pour rester au courant des
                                    derniers outils et techniques marketing.</b></h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <h3 class="mb-3 display-7 fw-bold"><b>La Success Blueprint des entrepreneurs
                                    visionnaires</b></h3>
                            <p class="text-muted mb-0">Un plan d’action détaillé et structuré pour te guider pas à pas,
                                du concept initial à la mise en œuvre concrète. Chaque étape est pensée pour maximiser
                                ton impact et t’éviter les erreurs coûteuses.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/presentiel.png" data-animate="tada slower infinite" width="100px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>L’Arme Secrète des leaders : Les outils d’IA à
                                    exploiter dès maintenant</b></h3>
                            <p class="text-muted mb-0">Découvre comment l’intelligence artificielle peut transformer ton
                                business : automatise tes tâches, multiplie ton efficacité et concentre-toi sur ce qui
                                génère vraiment des résultats.
                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg " data-bs-theme="dark">
                            <h3 class="mb-3 display-7 fw-bold"><b>Le Mindset Gagnant&nbsp;: La clé pour transformer tes
                                    ambitions en victoires</b></h3>
                            <p>Adopte l’état d’esprit des entrepreneurs à succès. Apprends à rester motivé, à surmonter
                                les obstacles et à transformer chaque défi en opportunité.
                            </p>
                            <!-- <img src="images/replay.png" data-animate="tada slower infinite" width="100px" class="center tada slower infinite animated" alt="Feature Icon"> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #DA2B35;">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>La Pricing Formula : Fixe des prix qui attirent et
                                    convertissent</b></h3>
                            <p>Une stratégie éprouvée pour valoriser ton offre et captiver tes
                                clients sans jamais brader ton travail. Apprends à calculer et présenter des prix
                                irrésistibles.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>La Signature Offer Framework : Conçois une offre
                                    impossible à refuser</b></h3>
                            <p class="text-muted mb-0">Structure une proposition qui parle directement aux besoins de
                                tes clients. Que ce soit un service ou un produit, fais de ton offre une évidence pour
                                ton marché.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>L’Impact Formula : Crée une marque qui reste gravée
                                    dans les esprits</b></h3>
                            <p class="text-muted mb-0">Développe une identité forte et mémorable qui te distingue
                                instantanément de tes concurrents. De la stratégie à l’exécution, fais de ton
                                business une référence.</p>
                        </div>
                    </div>


                </div>
            </div>
        </div>
    </div>
</section>





<section class="bonus-section">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center">

                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Des Bonus Exclusifs pour ta croissance
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Des Bonus Exclusifs pour ta croissance
                    </h6>
                    <br>
                    <svg viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg" width="50" height="50">
                        <path fill="#000000"
                            d="M104.704 338.752a64 64 0 0 1 90.496 0l316.8 316.8 316.8-316.8a64 64 0 0 1 90.496 90.496L557.248 791.296a64 64 0 0 1-90.496 0L104.704 429.248a64 64 0 0 1 0-90.496z"
                            style="fill: var(--white);" class=""></path>
                    </svg>
                </div>
                <div class="row justify-content-center align-items-stretch pt-5">


                    <div class="col-xl-4 col-md-6 pt-5 d-flex align-items-stretch">
                        <div class="card border-0 shadow-sm h-shadow shadow-ts p-3" style="background-color: #FFF8E0;">
                            <div class="card-body">
                                <h3 class="card-title h2 fw-bold mb-4">Le E-commerce Starter Pack&nbsp;: ton plan
                                    d’action clé en main</h3>
                                <p class="card-text mb-5">Découvre un plan étape par étape pour te lancer
                                    dans
                                    l’e-commerce,
                                    avec une liste de fournisseurs.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 pt-5 d-flex align-items-stretch">
                        <div class="card border-0 shadow-sm h-shadow shadow-ts p-3" style="background-color: #efefef;">
                            <div class="card-body">
                                <h3 class="card-title h2 fw-bold mb-4">Construis ton
                                    activité en ligne</h3>
                                <p class="card-text mb-5">Structure
                                    ton offre, trouve tes premiers clients, et fais décoller ton activité avec des
                                    étapes simples et efficaces.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 pt-5 d-flex align-items-stretch">
                        <div class="card border-0 dark shadow-sm h-shadow shadow-ts p-3"
                            style="background-color: #000000;" data-bs-theme="dark">
                            <div class="card-body">
                                <h3 class="card-title h2 fw-bold mb-4 ">La Liste Ultime des Outils IA&nbsp;: tout
                                    ce qu’il te faut pour réussir</h3>
                                <p class="card-text mb-5">Une sélection des meilleurs outils IA, triés par catégories et
                                    objectifs, pour automatiser tes tâches, personnaliser ton marketing, et gagner du
                                    temps au quotidien.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 pt-5 d-flex align-items-stretch">
                        <div class="card border-0 dark shadow-sm h-shadow shadow-ts p-3"
                            style="background-color: #efefef;" data-bs-theme="dark">
                            <div class="card-body">
                                <h3 class="card-title h2 fw-bold mb-4 text-black">Les 50 CTA et Hooks irrésistibles&nbsp;:
                                    booste tes vidéos et contenus</h3>
                                <p class="card-text mb-5 text-black">Obtiens une liste prête à l’emploi de
                                    Call-to-Actions et
                                    accroches puissantes pour captiver ton audience, générer de l’engagement, et
                                    transformer tes vues en ventes.

                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-md-6 pt-5 d-flex align-items-stretch">
                        <div class="card border-0 dark shadow-sm h-shadow shadow-ts p-3"
                            style="background-color: #000000;" data-bs-theme="dark">
                            <div class="card-body">
                                <h3 class="card-title h2 fw-bold mb-4 text-white">Les Fiches Pratiques et Exercices&nbsp;:
                                    passe de la théorie à l’action</h3>
                                <p class="card-text mb-5">Des fiches pratiques conçues pour t’aider à appliquer les
                                    concepts du guide immédiatement.

                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-4 col-md-6 pt-5 d-flex align-items-stretch">
                        <div class="card border-0 dark shadow-sm h-shadow shadow-ts p-3"
                            style="background-color: #FFF8E0;" data-bs-theme="dark">
                            <div class="card-body">
                                <h3 class="card-title h2 fw-bold mb-4 text-black">La Liste d’Idées de Business&nbsp;: à
                                    chaque budget son projet</h3>
                                <p class="card-text mb-5 text-black">Une compilation des meilleures idées de business
                                    selon ton
                                    budget.
                                    Cette liste te permet de trouver l’idée adaptée à tes moyens et de commencer
                                    dès aujourd’hui.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div id="section-pricing" class="page-section py-5 py-lg-6" style="background-color: #fff2de;">
    <div class="container">
        <div class="text-center">
            <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
            <h6 class="display-5 fw-bolder ls1 mb-5 position-relative d-none d-md-block">
                Génère de l'argent maintenant !
            </h6>
            <!-- Contenu visible uniquement sur les smartphones -->
            <h6 class="display-6 fw-bolder ls1 mb-5 position-relative d-block d-md-none">
                Génère de l'argent maintenant !
            </h6>

            <h6 class="display-3 fw-bolder ls1 position-relative strike color-red-price-off">
                467€
            </h6>
            <h6 class="display-4 fw-bolder ls1 position-relative strike color-red-price-off" style="margin-top: -28px;">
                397€
            </h6>
            <h6 class="display-5 fw-bolder ls1 position-relative strike color-red-price-off" style="margin-top: -28px;">
                247€
            </h6>
            <img width="380px" src="assets/images/simulator/gif-fleche-prix.gif">
        </div>

        <div id="pricing-switch" class="row justify-content-center pricing gx-lg-5">
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
                <span
                    class="badge px-3 py-2 mt-2 me-1 bg-white border border-default text-dark rounded-pill fw-normal position-absolute z-9"
                    style="left: 50px; top: -23px; background-color: #feae02 !important; color: white !important;">Le
                    plus populaire</span>
                <div class="pricing-box position-relative rounded-xxl p-5 h-100 d-flex flex-column"
                    style="background-color: #000000 !important;">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h3 class="fw-bolder fs-1 ls-n-1 mb-1 text-white">Starter</h3>
                            <p class="mb-0 small lh-base text-white">Guide + newsletter mensuelle exclusive.</p>
                        </div>

                        <div class="col-lg-6">
                            <div class="pricing-price fw-bolder text-lg-end pt-lg-0 pb-0">
                                <div class="pts-content pts-content-annually text-white">27€<span
                                        class="d-block fs-6 fw-semibold op-05 mb-0 mt-2 text-white">Paiement
                                        unique</span></div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="pricing-features bg-transparent border-bottom-0 border-width-2 border-black mt-5 pt-5 pb-3">
                        <p class="lead fw-bold mb-3 text-white">Accède au guide complet et reçois chaque mois une
                            newsletter exclusive pour booster ton business.</p>
                        <img src="assets/images/simulator/newsletter-sticker.png">
                    </div>
                    <div class="pricing-action p-0 mt-auto">
                        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                        <div class="d-none d-md-block">
                            <a (click)="checkout('starter')" *ngIf="checkoutButton === true"
                                class="button fw-bolder w-100 py-3 px-4 d-flex justify-content-between call-to-action-background-color rounded-pill mx-0">
                                OBTENIR LA STRATEGIE <i class="bi-arrow-right"></i></a>
                        </div>
                        <!-- Contenu visible uniquement sur les smartphones -->
                        <div (click)="checkout('starter')" *ngIf="checkoutButton === true" class="d-block d-md-none">
                            <a
                                class="button button-mini fw-bolder w-100 py-3 px-4 d-flex justify-content-between call-to-action-background-color rounded-pill mx-0">
                                OBTENIR LA STRATEGIE <i class="bi-arrow-right"></i></a>
                        </div>
                        <a *ngIf="checkoutButtonLoading === true"
                            class="button button-large button-circle button-green fw-bolder call-to-action-background-color">
                            <img src="assets/images/loading.gif" width="20px"></a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xl-5">
                <div class="pricing-box position-relative rounded-xxl p-5 h-100 d-flex flex-column bg-contrast-0"
                    style="background-color: #ffffff !important;">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <h3 class="fw-bolder fs-1 ls-n-1 mb-1">Premium</h3>
                            <p class="mb-0 small lh-base text-contrast-700">Guide + newsletter mensuelle + call
                                stratégique de 45 min.</p>
                        </div>

                        <div class="col-lg-6">
                            <div class="pricing-price fw-bolder text-lg-end pt-lg-0 pb-0">
                                <div class="pts-content pts-content-annually">197€<span
                                        class="d-block fs-6 fw-semibold op-05 mb-0 mt-2">Paiement unique</span></div>
                            </div>
                        </div>
                    </div>
                    <div
                        class="pricing-features bg-transparent border-bottom-0 border-width-2 border-black mt-5 pt-5 pb-3">
                        <p class="lead fw-bold mb-3">En plus du guide et de la newsletter, bénéficie d’un appel
                            stratégique de 45 minutes pour un accompagnement personnalisé.</p>
                        <img src="assets/images/simulator/call.png">
                    </div>

                    <div class="pricing-action p-0 mt-auto">
                        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                        <div class="d-none d-md-block">
                            <a (click)="checkout('premium')" *ngIf="checkoutButton === true"
                                class="button fw-bolder w-100 py-3 px-4 d-flex justify-content-between call-to-action-background-color rounded-pill mx-0">
                                OBTENIR LA STRATEGIE <i class="bi-arrow-right"></i></a>
                        </div>
                        <!-- Contenu visible uniquement sur les smartphones -->
                        <div (click)="checkout('premium')" *ngIf="checkoutButton === true" class="d-block d-md-none">
                            <a
                                class="button button-mini fw-bolder w-100 py-3 px-4 d-flex justify-content-between call-to-action-background-color rounded-pill mx-0">
                                OBTENIR LA STRATEGIE <i class="bi-arrow-right"></i></a>
                        </div>
                        <a *ngIf="checkoutButtonLoading === true"
                            class="button button-large button-circle button-green fw-bolder call-to-action-background-color">
                            <img src="assets/images/loading.gif" width="20px"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section style="margin-top: 30px;">
    <div class="content-wrap py-0">
        <div id="section-faqs" class="page-section my-5">
            <div class="container">
                <div class="row justify-content-between position-relative">
                    <div class="col-lg-5">
                        <h3 class="display-3 ls-n-3 fw-bolder mb-3">
                            <span class="color-important-word">Questions</span>
                            Fréquemment Posées
                        </h3>
                        <img src="assets/images/simulator/faq-guide.png" alt="...">
                    </div>
                    <div class="col-lg-6 bg-contrast-0 p-5 rounded-xxl pt-6">
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Combien de volumes contient le guide ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Notre "Guide ultime pour lancer son business" est composé de deux volumes :
                                - Le premier volume couvre les bases de la création d'entreprise.
                                - Le deuxième volume est entièrement consacré à l'intelligence artificielle et à son
                                application dans ton business.
                            </div>
                        </div>

                        <div class="line border-contrast-300 my-4"></div>

                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Quelle est la durée du plan d'action
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le guide propose un plan d'action sur 30 jours. Cependant, tu peux avancer à ton propre
                                rythme et prendre le temps nécessaire pour bien assimiler chaque étape.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce que le guide est remboursable ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Non, en raison de la nature numérique de ce produit, les achats ne sont pas
                                remboursables. Nous sommes convaincus que tu trouveras une valeur énorme dans notre
                                guide.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Comment vais-je recevoir le guide après l'achat ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Après ton paiement, tu recevras directement par email les deux volumes de notre guide.
                                Vérifie bien ton adresse email lors de la commande pour t'assurer de recevoir les
                                documents sans problème.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Qui puis-je contacter en cas de questions ou de problèmes ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Si tu as des questions ou rencontres des problèmes, n'hésite pas à nous contacter à
                                l'adresse suivante : contact&#64;cestnow.com. Nous sommes là pour t'aider et répondre à
                                toutes tes questions.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce que le guide est adapté à tous les niveaux d'entrepreneurs ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Oui, le guide est conçu pour s'adapter aux besoins des entrepreneurs débutants,
                                intermédiaires, et avancés. Chaque section du guide est structurée pour offrir des
                                conseils et des stratégies adaptés à ton niveau actuel.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Combien de temps faut-il pour voir les premiers résultats avec ce guide ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le temps pour voir les résultats peut varier en fonction de ton engagement et de ton
                                rythme de travail. Cependant, en suivant les étapes du plan d'action de 30 jours, tu
                                devrais commencer à voir des progrès significatifs dans ton projet entrepreneurial.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Puis-je partager le guide avec d'autres personnes ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le guide est destiné à un usage individuel et n'est pas conçu pour être partagé.
                                Cependant, si tu connais quelqu'un qui pourrait bénéficier du guide, nous t'encourageons
                                à lui recommander d'en acheter un exemplaire pour lui-même.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</section>


<section style="margin-top: -80px;">
    <div class="content-wrap">
        <div class="container">
            <div class="heading-block mb-5 text-center border-bottom-0 pt-3">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h2 class="text-transform-none fw-bolder d-none d-md-block">
                    Ils ont téléchargé <span class="fw-bolder color-important-word">le plan d'action 30 jours</span>
                </h2>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h3 class="text-transform-none fw-bolder d-block d-md-none">
                    Ils ont téléchargé <br><span class="fw-bolder color-important-word">le plan d'action 30 jours</span>
                </h3>
            </div>

            <div class="row user-testimonials">
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Honnêtement, je n’aurais jamais imaginé qu’un simple
                                guide puisse m’apporter autant. En suivant les étapes, j’ai créé une offre en 48 heures
                                et généré mes premiers 1 200 € en deux semaines. C’est clair, précis et surtout
                                actionnable. Merci pour ce bijou !</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Julien M.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Le guide m’a permis de structurer mon idée de
                                business, ce qui m’a toujours bloqué avant. En trois semaines, j’avais non seulement une
                                stratégie claire, mais aussi mes premières ventes. J’ai récupéré mon investissement en
                                moins de 10 jours !</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Amélie B.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Ce guide est différent. Pas de bla-bla inutile,
                                uniquement des actions concrètes et des exemples clairs. J’ai suivi le plan à la lettre
                                et j’ai lancé mon projet en un mois. Résultat : 5 400 € de CA dès le premier mois. Je
                                recommande à 100 % !</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Lucas T.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Avec ce guide, j’ai enfin osé me lancer. En
                                appliquant les étapes, j’ai fait 2 000 € en trois semaines. Si tu hésites, fonce, c’est
                                du concret !</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Sophie G.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Je n’y croyais pas au départ. Je pensais que
                                l’entrepreneuriat n’était pas pour moi. Mais avec ce guide, tout devient simple et
                                clair. Aujourd’hui, je vis de mon projet, et c’est en grande partie grâce à ces
                                conseils.</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Marc D.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Ce guide m’a fait gagner un temps fou ! Je n’y
                                connaissais rien, mais chaque étape est expliquée simplement. Je l’ai suivi sans me
                                poser de questions, et en moins d’un mois, j’avais mon premier client.</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Clément T.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Je l’ai acheté en me disant que c’était juste un
                                ebook comme les autres, mais je me suis trompé. J’ai découvert une méthode structurée
                                qui m’a permis d’avancer plus vite. Aujourd’hui, j’ai une vision claire et des résultats
                                concrets qui augmentent chaque semaine.</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Nadia P.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Un énorme merci à toute l’équipe ! Le call que j’ai
                                eu avec vous a été une révélation. Vos conseils étaient précis, adaptés à ma situation
                                et surtout immédiatement applicables. Grâce à ça, j’ai pu optimiser mon lancement et
                                générer mes premières ventes en quelques jours. Vous êtes incroyables, continuez comme
                                ça !</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Karim E.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Je tiens à remercier toute l’équipe pour le call
                                stratégique. Les conseils donnés étaient d’une clarté et d’une pertinence
                                impressionnantes. En 30 minutes, vous avez transformé ma vision du business et m’avez
                                donné des actions concrètes à appliquer. Résultat : j’ai triplé mes revenus en deux
                                mois. Bravo pour votre expertise et votre bienveillance !</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Ines O.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
            </div>



            <!-- <div class="row user-testimonials">
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Honnêtement, je n’aurais jamais imaginé qu’un simple
                                guide puisse m’apporter autant. En suivant les étapes, j’ai créé une offre en 48 heures
                                et généré mes premiers 1 200 € en deux semaines. C’est clair, précis et surtout
                                actionnable. Merci pour ce bijou !"
                            </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Julien M.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Grâce à ce guide, j'ai pu affiner mon modèle
                                d'affaires et optimiser mes stratégies de marketing. Les résultats ont été immédiats et
                                très bénéfiques.
                            </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Amélie B.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Je recommande vivement ce programme à quiconque
                                cherche à comprendre les principes fondamentaux de la gestion d'entreprise. Les leçons
                                sont claires, et les outils sont très pratiques.
                            </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Lucas T.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">J'étais sceptique au début, mais les conseils
                                personnalisés et les études de cas pertinents m'ont vraiment aidé à voir les
                                possibilités de croissance pour mon entreprise.
                            </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Marc D.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Un excellent investissement! Les stratégies
                                présentées m'ont aidé à maximiser mes profits et à réduire les dépenses inutiles. Un
                                must pour les propriétaires de petites entreprises.
                            </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Sophie G.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Ce programme a transformé ma façon de penser
                                l'entrepreneuriat. Les modules sur les finances et le marketing sont particulièrement
                                éclairants et immédiatement applicables.</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Nadia P.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Si vous démarrez juste ou si vous voulez donner un
                                nouveau souffle à votre business, ce programme est pour vous. L'approche étape par étape
                                est facile à suivre et très efficace.
                            </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Émile V.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>

            </div> -->
        </div>
    </div>
</section>



<app-simulator-footer></app-simulator-footer>