import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { SimulatorSession } from "../../../session/simulator.session";
import { SimulatorSessionv1 } from "../../../session/simulatorv1.session";
import { SimulatorService } from '../../../services/simulator.service';
import { FacebookService } from '../../../services/facebook.service';
import { environment } from '../../../../environments/environment';
import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-bu-01-cart',
  templateUrl: './bu-01-cart.component.html',
  styleUrls: ['./bu-01-cart.component.css']
})
export class Bu01CartComponent implements OnInit {

  // Contient les données de la session utilisateur
  session: any = {};

  // Gestion des erreurs
  error: any;

  // Variables pour le compte à rebours
  private initialTime = 12 * 60; // 12 minutes en secondes
  timeLeft: number = 0;
  subscription: Subscription | null = null;
  startTime: number | null = null;
  showCountdown: boolean = true;
  currentPrice: number = 0;

  // Prix du produit en fonction des promotions
  price: number = 147;
  pricePromo01: number = 97;
  pricePromo02: number = 27;

  // État d'activation/désactivation du bouton de paiement
  checkoutButton: any = true;
  // Indicateur de chargement pour le bouton de paiement
  checkoutButtonLoading: any = false;

  /**
   * Constructeur injectant les services nécessaires :
   * - Router : pour la navigation
   * - SimulatorService : pour appeler les API du backend liées au simulateur
   * - SimulatorSession, SimulatorSessionv1 : pour gérer les données de session utilisateur
   * - FacebookService : pour gérer les interactions avec Facebook Pixel
   */
  constructor(
    private router: Router,
    private simulatorService: SimulatorService,
    private simulatorSession: SimulatorSession,
    private simulatorSessionv1: SimulatorSessionv1,
    private facebookService: FacebookService
  ) { }

  /**
   * Méthode appelée lors de l'initialisation du composant.
   * - Récupère la session utilisateur.
   * - Initialise le compte à rebours et charge le script vidéo externe en fonction du sexe de l'utilisateur.
   * - Si la session est invalide, redirige l'utilisateur vers la première étape du simulateur.
   * - Envoie un événement "ReportGenerated" à Facebook Pixel.
   */
  ngOnInit(): void {
    this.loadCountdown();
    // console.log(`Initial timeLeft: ${this.timeLeft}, showCountdown: ${this.showCountdown}`);

    // Initialisation des états du bouton de paiement
    this.checkoutButton = true;
    this.checkoutButtonLoading = false;

    // Récupérer la session utilisateur
    this.session = this.simulatorSessionv1.getItem(environment.products.simulatorbu01.sessionId);

    // Charger le script vidéo externe en fonction du sexe de l'utilisateur
    if (this.session.gender.response === 'women') {
      this.loadExternalScript('https://fast.wistia.com/embed/medias/9c0h350k5y.jsonp');
      this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');
    } else if (this.session.gender.response === 'men') {
      this.loadExternalScript('https://fast.wistia.com/embed/medias/e3h0jd1d8t.jsonp');
      this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');
    }

    // Vérifier si la session est valide, sinon rediriger
    if (this.session === null || this.session.step2.check === false || this.session.step3.check === false || this.session.step4.check === false || this.session.step5.check === false || this.session.step6.check === false || this.session.step7.check === false || this.session.step8.check === false || this.session.step9.check === false) {
      this.router.navigateByUrl('/business-1/simulateur/etape/1');
    }

    // Initialiser Facebook Pixel et envoyer l'événement "ReportGenerated"
    this.facebookService.init(environment.products.simulatorbu01.pixel);
    this.facebookService.track('ReportGenerated');
    this.facebookService.sendEventToFacebook(environment.products.simulatorbu01.pixel, environment.products.simulatorbu01.accessToken, 'ReportGenerated');
  }

  /**
   * Méthode pour faire défiler la page jusqu'à un élément spécifique.
   * @param elementId - ID de l'élément cible.
   */
  scrollToElement(elementId: string): void {
    const element = document.querySelector(`#${elementId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  /**
   * Charge un script externe dans la page.
   * @param url - URL du script à charger.
   */
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  /**
   * Gère le processus de checkout lorsque l'utilisateur ajoute un produit au panier.
   * @param price - Le prix du produit sélectionné.
   */
  checkout(price: number) {
    this.session.price = this.currentPrice;

    this.checkoutButton = false;
    this.checkoutButtonLoading = true;

    this.simulatorSessionv1.setItem(environment.products.simulatorbu01.sessionId, this.session);
    this.session = this.simulatorSessionv1.getItem(environment.products.simulatorbu01.sessionId);
    this.session.stripe = environment.stripe;

    // Envoi de l'événement "AddToCart" à Facebook Pixel
    this.facebookService.track('AddToCart', {
      content_name: environment.products.simulatorbu01.ref,
      content_ids: environment.products.simulatorbu01.id,
      content_type: 'product',
      value: this.session.price,
      currency: environment.products.simulatorbu01.devise,
      quantity: 1
    });

    let cartObject = {
      content_name: environment.products.simulatorbu01.ref,
      content_ids: environment.products.simulatorbu01.id,
      content_type: 'product',
      value: this.session.price,
      currency: environment.products.simulatorbu01.devise,
      quantity: 1
    }

    this.facebookService.sendEventToFacebook(environment.products.simulatorbu01.pixel, environment.products.simulatorbu01.accessToken, 'AddToCart', this.session.stepEmail.response, cartObject);

    // Appel à l'API pour lancer le processus de checkout
    this.simulatorService.checkout(this.session).subscribe({
      next: (response: any) => {
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        window.location.href = response.session.url;
      },
      error: (error: any) => {
        this.error = true;
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        setTimeout(() => {
          this.error = null;
        }, 3500); // Afficher l'erreur pendant 3.5 secondes
      }
    });
  }

  /**
   * Charge ou reprend le compte à rebours depuis le local storage.
   */
  loadCountdown() {
    const savedTime = localStorage.getItem('countdownStartTime');
    if (savedTime) {
      const elapsed = Math.floor((Date.now() - parseInt(savedTime)) / 1000);
      this.timeLeft = this.initialTime - elapsed;
      if (this.timeLeft < 0) {
        this.timeLeft = 0; // Empêcher un compte à rebours négatif
      }
    } else {
      this.timeLeft = this.initialTime;
      localStorage.setItem('countdownStartTime', Date.now().toString());
    }
    this.startCountdown();
  }

  /**
   * Démarre le compte à rebours.
   */
  startCountdown() {
    // console.log(`Starting countdown with timeLeft: ${this.timeLeft}`);
    if (this.timeLeft <= 0) {
      this.currentPrice = this.pricePromo01;
      // console.log('No countdown needed, time already elapsed');
      this.showCountdown = false;
      this.cleanupSubscription();
      return; // Arrête le compte à rebours si le temps est déjà écoulé.
    }

    const timer$ = interval(1000).pipe(
      takeWhile(() => this.timeLeft > 0, true)
    );

    this.subscription = timer$.subscribe({
      next: () => {
        this.currentPrice = this.pricePromo02;
        this.timeLeft -= 1;
        // console.log(`Counting down: ${this.formatTime(this.timeLeft)}`);
        // console.log(this.formatTime(this.timeLeft));  
      },
      complete: () => {
        // console.log('Countdown completed');
        this.currentPrice = this.pricePromo01;
        this.showCountdown = false;
        this.cleanupSubscription();
      }
    });
  }

  /**
   * Nettoie la souscription au compte à rebours.
   */
  cleanupSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  /**
   * Formatte le temps restant en minutes et secondes.
   * @param seconds - Temps restant en secondes.
   * @returns Chaîne formatée avec minutes et secondes.
   */
  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    return `${this.padZero(minutes)} MIN ${this.padZero(remainingSeconds)} SEC`;
  }

  /**
   * Ajoute un zéro devant un chiffre inférieur à 10.
   * @param num - Le nombre à formater.
   * @returns Chaîne formatée avec un zéro si nécessaire.
   */
  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;
  }

  /**
   * Méthode appelée lors de la destruction du composant pour nettoyer la souscription.
   */
  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  /**
   * Réinitialise le cache du compte à rebours.
   */
  resetCountdownCache() {
    this.showCountdown = true;
    localStorage.removeItem('countdownStartTime');
    this.timeLeft = this.initialTime;
    localStorage.setItem('countdownStartTime', Date.now().toString());
    this.startCountdown();
  }

  /**
   * Récupère le titre de la réponse sélectionnée pour une étape donnée.
   * @param step - L'étape du simulateur.
   * @param responseNumber - Le numéro de la réponse.
   * @returns Le titre de la réponse ou un message par défaut.
   */
  getResponseTitleMen(step: any, responseNumber: string): string {
    const responseKey = `response${responseNumber}`;
    return step.responses[responseKey]?.title_bis_Men || 'Aucune réponse trouvée';
  }

  /**
   * Récupère le titre de la réponse sélectionnée pour une étape donnée pour un parcours Women.
   * @param step - L'étape du simulateur.
   * @param responseNumber - Le numéro de la réponse.
   * @returns Le titre de la réponse ou un message par défaut.
   */
  getResponseTitleWomen(step: any, responseNumber: string): string {
    const responseKey = `response${responseNumber}`;
    return step.responses[responseKey]?.title_bis_Women || 'Aucune réponse trouvée';
  }

  /**
   * Récupère le texte de vente associé à la réponse sélectionnée pour une étape donnée.
   * @param step - L'étape du simulateur.
   * @param responseNumber - Le numéro de la réponse.
   * @returns Le texte de vente ou un message par défaut.
   */
  getResponseSaleTextMen(step: any, responseNumber: string): string {
    const responseKey = `response${responseNumber}`;
    return step.responses[responseKey]?.saleTextMen || 'Aucune réponse trouvée';
  }

  /**
   * Récupère le texte de vente associé à la réponse sélectionnée pour une étape donnée pour un parcours Women.
   * @param step - L'étape du simulateur.
   * @param responseNumber - Le numéro de la réponse.
   * @returns Le texte de vente ou un message par défaut.
   */
  getResponseSaleTextWomen(step: any, responseNumber: string): string {
    const responseKey = `response${responseNumber}`;
    return step.responses[responseKey]?.saleTextWomen || 'Aucune réponse trouvée';
  }
}
