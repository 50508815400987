import { Component } from '@angular/core';
import {Router, ActivatedRoute } from '@angular/router';
import { jwtDecode } from "jwt-decode";

@Component({
  selector: 'app-cancel-sale-simulator',
  templateUrl: './cancel-sale-simulator.component.html',
  styleUrls: ['./cancel-sale-simulator.component.css']
})
export class CancelSaleSimulatorComponent {

  constructor(private router: Router,private route: ActivatedRoute) { }

  ngOnInit() {
    let id :any = this.route.snapshot.paramMap.get('id');      
    const decodedToken: any = jwtDecode(id);  
     
    this.router.navigate(['/'+decodedToken.ref+'/rapport']);
  }


}
