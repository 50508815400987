<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-12 col-lg-12">
                        <a routerLink="/ebook/guide-import-export/01/produit"
                            class="button  button-rounded fw-bolder"><i class="bi-arrow-left-circle-fill"></i></a>
                        <a routerLink="/ebook/guide-import-export/01/produit"
                            class="button  button-rounded fw-bolder">2.
                            Le produit</a>

                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- Budget de 500 à 2000 -->
<!-- réponse oui pour le produit -->
<section class="pt-5" *ngIf="session.step1.response === '01' && session.budget <= 2000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Tu sais ce que tu veux importer. Maintenant, tu passes à l’action.
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Tu sais ce que tu veux importer. Maintenant, tu passes à l’action.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">

                        <p class="lead fs-4 mb-5">
                            Avec {{session.budget | currency}} et une idée claire en tête, tu es déjà en train de poser
                            les bases d’un
                            business rentable. Ce budget est suffisant pour démarrer intelligemment, valider ton produit
                            et sécuriser tes premières importations.
                            <br>
                            tu vas identifier les meilleurs fournisseurs, tu optimises chaque euro
                            investi et tu construis une base solide pour passer au niveau supérieur.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            La Feuille de Route
                        </h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h1 class="display-7 text-black fw-bolder mb-0">Valider la Qualité</h1>
                            <p class="fw-bolder text-black mb-0">
                                ⚫ Tu contactes des fournisseurs fiables directement en Chine ou via des agents de
                                sourcing.<br>
                                ⚫Tu demandes des échantillons pour t’assurer que le produit respecte tes attentes et les
                                normes européennes (CE, RoHS, EN71, etc.).
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h1 class="display-7 text-black fw-bolder mb-0">Négocie </h1>
                            <p class="fw-bolder text-black mb-0"> ⚫ Tu utilises ta connaissance du produit pour négocier
                                des prix compétitifs, même pour de petites quantités.<br>
                                ⚫Tu privilégies les Incoterms FOB (Free On Board) pour réduire tes responsabilités
                                logistiques et optimiser tes coûts.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h1 class="display-7 text-black fw-bolder mb-0">Maximise Chaque Euro</h1>
                            <p class="fw-bolder text-black mb-0"> ⚫ Tu testes ton produit en petites quantités sur des
                                marketplaces comme Amazon ou Etsy pour valider la demande.<br>
                                ⚫ Tu choisis des solutions logistiques économiques, comme le groupage, pour transporter
                                tes marchandises à moindre coût.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Tu n’attends plus. Tu agis maintenant pour transformer ton idée en réalité. Ce budget,
                                utilisé avec une stratégie claire, te permet de poser les premières pierres de ton
                                succès. Chaque étape que tu franchis te rapproche de ton objectif</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- réponse non pour le produit -->
<section class="pt-5" *ngIf="session.step1.response === '02'  && session.budget <= 2000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Tu as le budget, mais tu ne sais pas encore quoi importer ? Pas de problème, on va clarifier
                        tout ça
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Tu as le budget, mais tu ne sais pas encore quoi importer ? Pas de problème, on va clarifier
                        tout ça
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-5" style="text-align: justify;">
                            Ne pas savoir quoi importer n’est pas un frein, c’est une opportunité. Avec {{session.budget
                            | currency}},
                            ton objectif est simple : trouver le produit parfait pour démarrer ton business et poser les
                            bases d’une activité rentable. Le marché regorge d’opportunités, et il suffit de suivre une
                            stratégie claire pour dénicher des idées de produits à fort potentiel.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            La Feuille de Route
                        </h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h1 class="display-7 text-black fw-bolder mb-0">Explore les Tendances du Marché :</h1>
                            <p class="fw-bolder text-black mb-0">⚫ Tu regardes les produits qui explosent sur les
                                marketplaces comme Amazon, Etsy, ou eBay.<br>
                                ⚫ Tu identifies des secteurs à forte demande : bien-être, maison, électronique, ou
                                accessoires.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h1 class="display-7 text-black fw-bolder mb-0">Trouve un Produit qui Répond à un Besoin
                            </h1>
                            <p class="fw-bolder text-black mb-0">⚫ Tu te demandes : "Quels problèmes puis-je résoudre
                                avec mon produit ?"<br>
                                ⚫ Tu cibles des produits légers, faciles à transporter et avec une forte valeur perçue.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h1 class="display-7 text-black fw-bolder mb-0">Teste les Niches Stratégiques
                            </h1>
                            <p class="fw-bolder text-black mb-0">⚫ Tu ne te limites pas à une seule idée : tu testes
                                plusieurs niches avec de petites quantités pour voir ce qui fonctionne.<br>
                                ⚫ Tu utilises des outils comme Google Trends ou des plateformes d’analyse pour
                                identifier les opportunités.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Ne pas savoir quoi importer, c’est normal. Ce qui compte, c’est que tu passes à l’action.
                                Chaque grande réussite a commencé par une exploration. Maintenant, c’est à toi de
                                construire ton succès, une étape à la fois.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- Budget de 2000 à 5000 -->
<!-- réponse oui pour le produit -->
<section class="pt-5" *ngIf="session.step1.response === '01'  && session.budget > 2000 && session.budget <= 5000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Tu sais ce que tu veux, et avec {{session.budget | currency}}, tu as le pouvoir de structurer un
                        business rentable.
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Tu sais ce que tu veux, et avec {{session.budget | currency}}, tu as le pouvoir de structurer un
                        business rentable.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-0">
                            Avec ce budget, tu entres dans une nouvelle dimension. Ce n’est plus un simple test, mais
                            une vraie opportunité de poser les bases solides de ton activité. Tu as la clarté d’un
                            produit en tête, et ton capital te permet de commencer avec des volumes stratégiques pour
                            optimiser tes coûts et maximiser tes bénéfices.
                        </p>
                        <hr class="pt-0">
                        <p class="lead fs-4 mb-5">
                            Ton objectif maintenant ? Déployer une stratégie efficace pour garantir la qualité de tes
                            produits, réduire tes risques et conquérir ton marché.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            La Feuille de Route
                        </h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h1 class="display-7 text-black fw-bolder mb-0">Tu passes des commandes stratégiques</h1>
                            <p class="fw-bolder text-black mb-0">⚫ Tu travailles avec des fournisseurs fiables et passes
                                des commandes intermédiaires pour négocier de meilleurs prix.<br>
                                ⚫ Tu sécurises des contrats pour éviter les mauvaises surprises.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h1 class="display-7 text-black fw-bolder mb-0">Tu investis dans la logistique adaptée
                            </h1>
                            <p class="fw-bolder text-black mb-0">⚫ Tu choisis les Incoterms FOB (Free On Board) ou CIF
                                (Cost, Insurance, and Freight) pour une meilleure maîtrise de tes coûts.<br>
                                ⚫ Tu privilégies des solutions comme le groupage pour optimiser tes frais de transport.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h1 class="display-7 text-black fw-bolder mb-0">Tu peaufines ton produit pour le marché
                            </h1>
                            <p class="fw-bolder text-black mb-0">⚫ Tu t’assures que le produit respecte les normes
                                européennes (CE, RoHS, etc.)<br>
                                ⚫ Tu réfléchis à la personnalisation (packaging, design) pour maximiser sa valeur
                                perçue.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Ce budget est une véritable force. Tu es à l’étape où chaque euro investi peut te
                                rapporter 10 fois plus. La clarté de ton idée, combinée à une bonne exécution, te met
                                sur la voie du succès. Maintenant, c’est à toi d’agir</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>
<!-- réponse non pour le produit -->
<section class="pt-5" *ngIf="session.step1.response === '02'  && session.budget > 2000 && session.budget <= 5000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Tu as {{session.budget | currency}}, mais pas encore d’idée ? Pas de souci, c’est le moment
                        d’explorer et de
                        trouver l’opportunité parfaite.
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Tu sais ce que tu veux, et avec {{session.budget | currency}}, tu as le pouvoir de structurer un
                        business rentable.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-0">
                            Avec ce budget, tu peux aller bien au-delà des tests initiaux et commencer à construire une
                            activité solide. Même sans idée précise, tu es à un tournant stratégique. Le marché regorge
                            d’opportunités, et ton capital te permet d’explorer plusieurs pistes jusqu’à trouver celle
                            qui fera décoller ton business.
                        </p>
                        <hr class="pt-0">
                        <p class="lead fs-4 mb-5">
                            Ton objectif ? Identifier les bonnes niches, valider les produits rentables et poser les
                            bases d’un projet qui fonctionne.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            La Feuille de Route
                        </h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h1 class="display-7 text-black fw-bolder mb-0">Tu explores les tendances rentables</h1>
                            <p class="fw-bolder text-black mb-0">⚫ Tu analyses les produits en forte demande sur des
                                plateformes comme Amazon, Etsy ou eBay.<br>
                                ⚫ Tu identifies des niches prometteuses dans des secteurs comme le bien-être, la maison
                                ou les accessoires.</p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h1 class="display-7 text-black fw-bolder mb-0">Tu valides tes idées par des tests
                            </h1>
                            <p class="fw-bolder text-black mb-0">⚫ Tu importes de petites quantités sur différentes
                                niches pour voir ce qui fonctionne.<br>
                                ⚫ Tu utilises les retours clients pour ajuster et affiner ton produit.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h1 class="display-7 text-black fw-bolder mb-0">Tu te concentres sur des produits
                                stratégiques :
                            </h1>
                            <p class="fw-bolder text-black mb-0">⚫ Légers, faciles à transporter et à forte valeur
                                perçue.<br>
                                ⚫ Conformes aux attentes des consommateurs européens : écologiques, innovants ou
                                abordables.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Avec ce budget, tu as déjà un avantage important : la capacité d’explorer sans trop de
                                risques. Trouver le bon produit, c’est le début d’une aventure incroyable. Une fois que
                                tu auras identifié ta niche, tu seras prêt à passer à l’action et à transformer ce
                                capital en succès</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()"
                                    class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>