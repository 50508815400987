<p>ebook05-step05 works!</p>
<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-12 col-lg-12">
                        <a routerLink="/ebook/guide-import-export/04/societe"
                            class="button  button-rounded fw-bolder"><i class="bi-arrow-left-circle-fill"></i></a>
                        <a routerLink="/ebook/guide-import-export/04/societe"
                            class="button  button-rounded fw-bolder">4.
                            Bilan</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="content-wrap">
        <div class="container">
            <div class="text-center mb-5">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                   ..............................................
                </h3>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                    ..............................................
                </h3>
            </div>
            <div class="row feature-box-border-vertical col-mb-50 justify-content-center align-items-center my-5">
                <div class="col-md-6 feature-box fbox-active fbox-dark fbox-sm">
                    <div class="fbox-icon">
                        <i class="fa-solid fa-check"></i>
                    </div>
                    <div class="fbox-content">
                        <h3 class="text-transform-none mb-2">Buget départ</h3>
                        <p>La mise de départ de {{session.budget | currency}}</p>
                    </div>
                </div>

                <div class="clear"></div>

                <div class="col-md-6 feature-box fbox-active fbox-border fbox-light fbox-sm">
                    <div class="fbox-icon">
                        <i class="fa-solid fa-check border"></i>
                    </div>
                    <div class="fbox-content">
                        <h3 class="text-transform-none mb-2">Produit à importer</h3>
                        <p>Oui il y'a une produit déja a importer</p>
                    </div>
                </div>

                <div class="clear"></div>

                <div class="col-md-6 feature-box fbox-active fbox-border fbox-light fbox-sm">
                    <div class="fbox-icon">
                        <i class="fa-solid fa-check border"></i>
                    </div>
                    <div class="fbox-content">
                        <h3 class="text-transform-none mb-2">Societé</h3>
                        <p>Mumbai, MH, IND - Sep 12th, 09:33pm</p>
                        <p class="mb-3">Completely mesh standardized expertise after synergistic architectures.</p>
                        <a href="#" class="link-primary"><u>See more details</u></a>
                    </div>
                </div>

                <div class="clear"></div>

                <div class="col-md-6 feature-box fbox-border fbox-light fbox-sm">
                    <div class="fbox-icon">
                        <i class="fa-solid fa-check border"></i>
                    </div>
                    <div class="fbox-content">
                        <h3 class="text-transform-none mb-2">Garantir son importation</h3>
                    </div>
                </div>

                <div class="clear"></div>

                <div class="col-md-6 feature-box fbox-border fbox-light noborder fbox-sm">
                    <div class="fbox-icon">
                        <i class="fa-solid fa-check border"></i>
                    </div>
                    <div class="fbox-content">
                        <h3 class="text-transform-none mb-0">Passer de 66% à 0% d'înpot</h3>
                    </div>
                </div>

            </div>

            <div class="col-lg-8 text-center pb-3">
                <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                    <div class="col-sm-6 col-xl-5">
                        <a class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                            style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                    </div>
                </div>
            </div>



        </div>
    </div>
</section>