export const environment = {
    production: false,
    apiBaseUrl: "/api",
    simulatorCheckoutCancelUrl: 'http://dev.cestnow.com/simulator/cancel',
    simulatorCheckoutSuccessUrl: 'http://dev.cestnow.com/business-1/sucess',
    // checkoutCancelUrl: 'https://dev.cestnow.com/simulator/report/resume',
    // checkoutSuccessUrl: 'https://dev.cestnow.com/simulator/success',
    ebookCheckoutCancelUrl: 'https://dev.cestnow.com/cancel',
    ebookCheckoutSuccessUrl: 'https://dev.cestnow.com/ebook/success',
    stripe: 'sk_test_51O2BOVEujlwdtd0iepX0Q1XqeY2Xi2wZ5a1YXF5x3BeLXElNzOJLOa4na0iULnOdKlUenkP48O64q9P8aUlnspQu00V2lGFmMW',
    products: {
        simulatorbu01: {
            id: 'simulateur-bu-01',
            sessionId: 'simulateur-bu-01',
            ref: 'le-guide-ultime',
            pixel: '933734658480919',
            accessToken: 'EAAXOKS1jm9wBO98x9sZBvP7WDiFyfNHpUgCihTwSsNmZAWqxWaAI4iZCJhUHXkZBVrOBsrsR1xhZC698kGZBoA0JBr83XRq7OfaGFXZCqWLic5h5RrZCHwhAyVdzhuZAmZAqzt4lL0Fajf4vdufMk4ft7poNjscMGkIilnQ3d9DZAEIF5zxDGcMgNOSHnlVuSPcnO9YqgZDZD',
            devise: 'EUR',
            product: {
                id: 'prod_Qzb3ymAgL3nKj8',
                price: [
                    {
                        amount: 147,
                        devise: 'EUR',
                        id: 'price_1Q7brGEujlwdtd0itrNqVVZR'
                    },
                    {
                        amount: 97,
                        devise: 'EUR',
                        id: 'price_1Q7bsvEujlwdtd0ij9Q0G17O'
                    },
                    {
                        amount: 27,
                        devise: 'EUR',
                        id: 'price_1Q7bthEujlwdtd0iHqpodLqW'
                    },
                ]
            }
        },
        ebooks: {
            ebook01: {
                id: 'ebook01',
                ref: 'comment-elever-l-amour-propre-et-guérir-l-esprit-le-corps-et-l-ame',
                pixel: '1111397830067390',
                accessToken: 'EAAXOKS1jm9wBO98x9sZBvP7WDiFyfNHpUgCihTwSsNmZAWqxWaAI4iZCJhUHXkZBVrOBsrsR1xhZC698kGZBoA0JBr83XRq7OfaGFXZCqWLic5h5RrZCHwhAyVdzhuZAmZAqzt4lL0Fajf4vdufMk4ft7poNjscMGkIilnQ3d9DZAEIF5zxDGcMgNOSHnlVuSPcnO9YqgZDZD',
                price: 0,
                devise: 'EUR',
                product: {
                    id: 'prod_QzgSH60WOXwB8X',
                    price: [
                        {
                            amount: 17,
                            devise: '€',
                            id: 'price_1Q7h5jEujlwdtd0i8pmIliyd'
                        },
                        {
                            amount: 7.97,
                            devise: '€',
                            id: 'price_1Q7h6bEujlwdtd0iy32xagX8'
                        },
                    ]
                }
            },
            ebook02: {
                id: 'ebook02',
                ref: 'gagner-de-l-argent-sans-te-montrer-guide',
                price: 'price_1P63i2BTvHG2hcfS5Jb6GeKl'

            },
            ebook03: {
                id: 'ebook03',
                ref: 'guide-faceless-reels-ia',
                pixel: '933734658480919',
                accessToken: 'EAAXOKS1jm9wBO98x9sZBvP7WDiFyfNHpUgCihTwSsNmZAWqxWaAI4iZCJhUHXkZBVrOBsrsR1xhZC698kGZBoA0JBr83XRq7OfaGFXZCqWLic5h5RrZCHwhAyVdzhuZAmZAqzt4lL0Fajf4vdufMk4ft7poNjscMGkIilnQ3d9DZAEIF5zxDGcMgNOSHnlVuSPcnO9YqgZDZD',
                price: 0,
                devise: 'EUR',
                product: {
                    id: 'prod_Qzg0HHHFY8zSHZ',
                    price: [
                        {
                            amount: 97,
                            devise: '€',
                            id: 'price_1Q7gksEujlwdtd0i0tAIuKLS'
                        },
                        {
                            amount: 27,
                            devise: '€',
                            id: 'price_1Q7gjWEujlwdtd0iO1AtO50d'
                        },
                        {
                            amount: 17.97,
                            devise: '€',
                            id: 'price_1Q7ge9Eujlwdtd0iSt4hUj14'
                        },
                    ]
                }
            },
            ebook04: {
                id: 'ebook04',
                ref: 'guide-faceless-reels-instagram-tiktok',
                pixel: '933734658480919',
                accessToken: 'EAAXOKS1jm9wBO98x9sZBvP7WDiFyfNHpUgCihTwSsNmZAWqxWaAI4iZCJhUHXkZBVrOBsrsR1xhZC698kGZBoA0JBr83XRq7OfaGFXZCqWLic5h5RrZCHwhAyVdzhuZAmZAqzt4lL0Fajf4vdufMk4ft7poNjscMGkIilnQ3d9DZAEIF5zxDGcMgNOSHnlVuSPcnO9YqgZDZD',
                price: 0,
                devise: 'EUR',
                product: {
                    id: 'prod_Px1MFM1pBTfMi0',
                    price: [
                        {
                            amount: 97,
                            devise: '€',
                            id: 'price_1PpYcyBTvHG2hcfS4ZhKSWJo'
                        },
                        {
                            amount: 27,
                            devise: '€',
                            id: 'price_1PpYdjBTvHG2hcfSB2q6afKu'
                        },
                        {
                            amount: 17.97,
                            devise: '€',
                            id: 'price_1PpYf2BTvHG2hcfSx0NoX0sv'
                        },
                    ]
                }
            }
        }
    }
};


