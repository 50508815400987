import { Component } from '@angular/core';
import { jwtDecode } from "jwt-decode";
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute } from "@angular/router";
import { FacebookService } from '../../../services/facebook.service';
import { SimulatorService } from '../../../services/simulator.service';
import { SimulatorSessionv1 } from "../../../session/simulatorv1.session";

@Component({
  selector: 'app-bu-01-sucess-sale',
  templateUrl: './bu-01-sucess-sale.component.html',
  styleUrls: ['./bu-01-sucess-sale.component.css']
})
export class Bu01SucessSaleComponent {

  // Contient les données de la session utilisateur
  session: any = {};

  // Indicateurs d'état pour la gestion du token JWT
  expired: any;
  tokenValid: any = true;
  errorToken: any;
  error: any;

  // Référence du fichier ebook et le point associé à l'utilisateur
  refEbook: any;
  point: any = 0;

  // Gestion de l'état des boutons de téléchargement
  downloadButton: any = true;
  downloadButtonLoading: any = false;

  /**
   * Constructeur injectant les services nécessaires :
   * - Router : pour la navigation
   * - ActivatedRoute : pour accéder aux paramètres de route
   * - FacebookService : pour gérer les interactions avec Facebook Pixel
   * - SimulatorService : pour appeler les API du backend liées au simulateur
   * - SimulatorSessionv1 : pour gérer les données de session utilisateur
   */
  constructor(
    private router: Router, 
    private route: ActivatedRoute, 
    private facebookService: FacebookService, 
    private simulatorService: SimulatorService, 
    private simulatorSessionv1: SimulatorSessionv1
  ) { }

  /**
   * Méthode appelée lors de l'initialisation du composant.
   * - Récupère la session utilisateur.
   * - Décode le token JWT reçu en paramètre d'URL.
   * - Si la session est absente, redirige l'utilisateur vers la première étape du simulateur.
   */
  ngOnInit() {
    this.session = this.simulatorSessionv1.getItem(environment.products.simulatorbu01.sessionId);  
    const token: any = this.route.snapshot.paramMap.get('id');
    this.decodeToken(token);
    if (this.session === null) {
      this.router.navigateByUrl('/business-1/simulateur/etape/0');
    }
  }

  /**
   * Décode le token JWT et vérifie sa validité.
   * @param token - Token JWT à décoder.
   */
  decodeToken(token: any): any {
    try {
      const decodedToken: any = jwtDecode(token);      
      this.point = decodedToken.point;

      // Vérification de l'expiration du token
      const expirationTimestamp: number = decodedToken.exp * 1000; // Convertir la date d'expiration en millisecondes
      const currentTimestamp: number = Date.now(); // Obtenir le timestamp actuel en millisecondes
      if (currentTimestamp > expirationTimestamp) {
        this.expired = true;
        this.tokenValid = false;
        this.errorToken = false;
        this.error = false;
      } else {
        this.tokenValid = true;
        this.expired = false;
        this.errorToken = false;
        this.error = false;

        // Préparation de l'objet d'achat pour le suivi de l'événement d'achat
        let purchaseObject = {
          content_name: environment.products.simulatorbu01.ref,
          content_ids: environment.products.simulatorbu01.id,
          content_type: 'product',
          value: this.session.price,
          currency: environment.products.simulatorbu01.devise,
          quantity: 1
        }

        // Initialisation et suivi de l'événement d'achat avec Facebook Pixel
        this.facebookService.init(this.session.pixel);
        this.facebookService.track('Purchase', purchaseObject);
        this.facebookService.sendEventToFacebook(environment.products.simulatorbu01.pixel, environment.products.simulatorbu01.accessToken, 'Purchase', '', purchaseObject);
      }
    } catch (error) {
      // Gestion des erreurs lors du décodage du token
      this.errorToken = true;
      this.tokenValid = false;
      this.expired = false;
      this.error = false;
    }
  }

  /**
   * Méthode pour gérer le téléchargement du fichier lié au business de l'utilisateur.
   * - Affiche un bouton de téléchargement et gère les états de téléchargement.
   */
  getBusinessFile() {
    this.downloadButton = false;
    this.downloadButtonLoading = true;

    setTimeout(() => {
      this.simulatorService.getBusinessFile(this.point).subscribe({
        next: (response: any) => {
          const url = window.URL.createObjectURL(response);
          const a = document.createElement('a');
          document.body.appendChild(a);
          a.href = url;
          a.download = 'guide-ultime-lancer-son-business.pdf'; // Nom du fichier à télécharger
          a.click();
          window.URL.revokeObjectURL(url);
          this.downloadButton = true;
          this.downloadButtonLoading = false;
        },
        error: (error) => {
          // Gestion des erreurs lors du téléchargement du fichier
          this.error = true;
          this.tokenValid = true;
          this.errorToken = false;
          this.expired = false;
          this.downloadButton = true;
          this.downloadButtonLoading = false;

          setTimeout(() => {
            this.error = false;
          }, 5000);
        }
      });
    }, 2000);
  }
}
