import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { Ebook05Session } from "../../../session/ebook05.session";
import { environment } from '../../../../environments/environment';


@Component({
  selector: 'app-ebook05-step00',
  templateUrl: './ebook05-step00.component.html',
  styleUrls: ['./ebook05-step00.component.css']
})
export class Ebook05Step00Component {

  error: any;
  // Variables pour gérer l'état des cartes sélectionnées
  isCardSelected01: boolean = false;
  isCardSelected02: boolean = false;


  routerlink: any = '/ebook/guide-import-export/01/produit'

  // Session de simulation, pour stocker et récupérer les données de l'utilisateur
  session: any = {};


  constructor(private ebook05Session: Ebook05Session, private router: Router) {
  }

  // Fonction exécutée lors de l'initialisation du composant
  ngOnInit(): void {
    // Récupère la session utilisateur
    this.session = this.ebook05Session.getItem(environment.products.ebooks.ebook05.sessionId);
    console.log(this.session);
    // Si la session est nulle ou si le genre n'a pas été sélectionné, redirige vers l'étape précédente
    // if (this.session === null || this.session.gender.check === false) {
    //   this.router.navigateByUrl('/business-1/simulateur/etape/0');         
    // }

    // // Initialise le pixel Facebook pour le suivi des événements
    // this.facebookService.init(environment.products.simulatorbu01.pixel);
  }


  navigateNextStep() {
    this.router.navigateByUrl('/ebook/guide-import-export/01/produit');    
  }
}
