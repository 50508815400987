<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-12 col-lg-12">
                        <a routerLink="/ebook/guide-import-export/00/invest" class="button  button-rounded fw-bolder"><i
                                class="bi-arrow-left-circle-fill"></i></a>
                        <a routerLink="/ebook/guide-import-export/00/invest" class="button  button-rounded fw-bolder">2.
                            L'idée ?</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>


<section class="pt-5 py-10" style="margin-top: 90px;">
    <div class="container center pt-1">
        <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
        <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
            Tu sais ce que tu veux importer ?
        </h3>
        <!-- Contenu visible uniquement sur les smartphones -->
        <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
            Tu sais ce que tu veux importer ?
        </h3>
    </div>
</section>



<section class="">
    <div class="content-wrap">
        <div class="border-bottom ">
            <div class="container">
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                                        
                    <!--Tile 01-->
                    <div class="col-6 col-md-3 text-center ">
                        <div class="card bg-opacity-10 " [ngStyle]="{'background-color': isCardSelected01 ? session.theme.backgroundColor : '#F0F1F2','border': session.step1.response === '01' ? session.theme.borderColor : 'none' }">                               
                            <div (click)="navigateNextStep('01')" class="card-body p-4 pointer">
                                <a (click)="navigateNextStep('01')" class="button button-large button-circle fw-bolder">
                                    <i class="fa-solid fa-check"></i>
                                    Oui
                                </a>                                
                            </div>
                        </div>
                    </div>
                    <!--Tile 02-->
                    <div class="col-6 col-md-3 text-center ">
                        <div class="card bg-opacity-10 " [ngStyle]="{            
                            'background-color': isCardSelected02 ? session.theme.backgroundColor : '#F0F1F2',                    
                                'border': session.step1.response === '02' ? session.theme.borderColor : 'none'
                                }">                                   
                            <div (click)="navigateNextStep('02')" class="card-body p-4 pointer">
                                <a (click)="navigateNextStep('02')" class="button button-large button-circle fw-bolder">
                                    <i class="fa-solid fa-check"></i>
                                    Non
                                </a>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>