<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-12 col-lg-12">
                        <a routerLink="/ebook/guide-import-export" class="button  button-rounded fw-bolder"><i
                                class="bi-arrow-left-circle-fill"></i></a>
                        <a routerLink="/ebook/guide-import-export" class="button  button-rounded fw-bolder">1.
                            Le Budget</a>
                        <!-- <a *ngIf="session.budget <= 2000" routerLink="/ebook/guide-import-export"
                            class="button  button-rounded fw-bolder">1.
                            L’Ambitieux qui Ouvre les Portes du Succès</a>
                        <a *ngIf="session.budget > 2000 && session.budget <= 5000"
                            routerLink="/ebook/guide-import-export" class="button  button-rounded fw-bolder">1.
                            Le Visionnaire qui Prend de l’Avance</a>
                        <a *ngIf="session.budget > 5000 && session.budget <= 15000"
                            routerLink="/ebook/guide-import-export" class="button  button-rounded fw-bolder">1.
                            Le Constructeur de Richesse</a>
                        <a *ngIf="session.budget > 15000 && session.budget <= 25000"
                            routerLink="/ebook/guide-import-export" class="button  button-rounded fw-bolder">1.
                            Le Professionnel Qui Joue Gagnant</a>                        
                        <a *ngIf="session.budget > 25000 && session.budget <= 50000"
                            routerLink="/ebook/guide-import-export" class="button  button-rounded fw-bolder">1.
                            Le Stratège Visionnaire</a>
                        <a *ngIf="session.budget > 50000 && session.budget <= 100000"
                            routerLink="/ebook/guide-import-export" class="button  button-rounded fw-bolder">1.
                            L’Architecte de l’Expansion</a>
                        <a *ngIf="session.budget > 100000" routerLink="/ebook/guide-import-export"
                            class="button  button-rounded fw-bolder">1.
                            Le Leader Global</a> -->
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>

<section class="pt-5" *ngIf="session.budget <= 2000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-5" style="text-align: justify;">
                            Vous rêvez de construire quelque chose de grand, mais vous pensez que vous n’avez pas
                            assez ? Détrompez-vous. Avec un budget de <span>{{session.budget | currency}}</span>, vous
                            avez déjà les clés pour
                            franchir la première étape de votre liberté financière. Voici comment :
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            Les 3 points clé !
                        </h3>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <p class="fw-bolder text-black mb-0">Apprenez à identifier les meilleurs fournisseurs
                                directement
                                sur les marchés chinois
                                locaux, sans intermédiaires coûteux.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Maîtrisez les bases des Incoterms comme EXW pour
                                contrôler
                                vos coûts et sécuriser vos
                                transactions.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Testez des niches stratégiques et des produits
                                prometteurs
                                pour démarrer votre
                                ascension.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <!-- <blockquote>
                            <p>Le succès n’est pas une question de capital, mais de stratégie. Ce budget peut être votre
                                premier pas vers une activité rentable et évolutive. Agissez maintenant et commencez
                                votre voyage dans le business de l'import export.</p>
                        </blockquote> -->
                        <blockquote>
                            <p>Ce budget n’est pas une limitation, c’est une opportunité. Les grands entrepreneurs ont
                                tous commencé par une première étape. Êtes-vous prêt à faire de ce moment le point de
                                départ de votre succès ?</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="pt-5" *ngIf="session.budget > 2000 && session.budget <= 5000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-3" style="text-align: justify;">
                            Vous avez les moyens de faire les choses correctement, et ce budget de
                            <span>{{session.budget | currency}}</span> est le tremplin idéal
                            pour transformer une idée en un business florissant. Voici ce que vous pouvez faire avec :
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            Les 3 points clé !
                        </h3>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <p class="fw-bolder text-black mb-0">Importer des volumes stratégiques directement depuis
                                les marchés chinois pour maximiser vos marges.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Travailler avec des agents de sourcing et des
                                transitaires fiables pour sécuriser chaque étape.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Mettre en place des stratégies qui vous permettent de
                                passer de 2 000 € à des milliers d’euros de bénéfices mensuels.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Vous êtes à un moment crucial. Ce budget peut être le levier qui vous propulse loin
                                devant ceux qui hésitent encore. La seule question est : voulez-vous agir aujourd’hui
                                pour construire ce que vous rêvez d’accomplir ?</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="pt-5" *ngIf="session.budget > 5000 && session.budget <= 15000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-3" style="text-align: justify;">
                            Avec un budget de <span>{{session.budget | currency}}</span>, vous êtes à un tournant
                            décisif. Ce n’est plus un
                            simple projet, mais une opportunité de bâtir une entreprise qui rapporte. Voici ce que vous
                            pouvez faire :
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            Les 3 points clé !
                        </h3>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <p class="fw-bolder text-black mb-0">Collaborer avec des fournisseurs locaux chinois pour
                                des volumes significatifs qui augmentent vos profits.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Personnaliser vos produits pour créer une marque forte
                                et inoubliable.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Optimiser votre logistique grâce à des Incoterms comme
                                CIF ou DDP, vous garantissant une tranquillité totale sur les livraisons.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Avec ce capital, vous avez le pouvoir de transformer une simple activité en une véritable
                                machine à cash. Il n’y a qu’une seule limite : ce que vous décidez de faire maintenant.
                                Prenez les commandes de votre succès.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="pt-5" *ngIf="session.budget > 15000 && session.budget <= 25000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-3" style="text-align: justify;">
                            Avec {{session.budget | currency}}, vous ne faites pas les choses à moitié. Vous êtes ici
                            pour jouer grand, et voici comment ce budget peut vous propulser :
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            Les 3 points clé !
                        </h3>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <p class="fw-bolder text-black mb-0">Créer une chaîne d’approvisionnement sécurisée et
                                optimisée en collaborant directement avec des usines chinoises.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Lancer une marque premium qui domine sa niche grâce à
                                des produits de qualité et une stratégie marketing puissante.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Optimiser vos importations en négociant des contrats à
                                long terme avec des transitaires et partenaires locaux.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Ce budget est une arme puissante. Bien utilisé, il vous permet de construire un business
                                qui génère des revenus stables, grandit rapidement et vous place parmi les meilleurs.
                                Êtes-vous prêt à jouer gagnant ?</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="pt-5" *ngIf="session.budget > 25000 && session.budget <= 50000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-3" style="text-align: justify;">
                            Avec un budget de {{session.budget | currency}}, vous êtes en mesure de dominer un marché.
                            Ce n’est
                            plus une question de commencer, mais de conquérir. Voici ce que vous pouvez accomplir :
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            Les 3 points clé !
                        </h3>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <p class="fw-bolder text-black mb-0">Importer des volumes massifs avec des économies
                                d’échelle qui boostent vos marges.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Créer une présence sur plusieurs marchés européens
                                grâce à une stratégie multi-canal (e-commerce, B2B).</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Travailler avec des fournisseurs premium pour offrir
                                des produits inégalables à vos clients.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Ce budget est votre passeport pour passer de l’entrepreneur à l’incontournable. Il est
                                temps de bâtir une entreprise qui ne se contente pas de suivre, mais qui dicte les
                                règles du jeu.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="pt-5" *ngIf="session.budget > 50000 && session.budget <= 100000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-3" style="text-align: justify;">
                            Avec {{session.budget | currency}}, vous êtes prêt à construire un véritable empire
                            commercial. Voici les leviers à activer :
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            Les 3 points clé !
                        </h3>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <p class="fw-bolder text-black mb-0">Négociez directement avec des fabricants haut de gamme
                                en Chine pour des produits exclusifs.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Mettez en place une chaîne logistique robuste,
                                réduisant les coûts unitaires et augmentant la rentabilité.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Lancez une stratégie marketing agressive pour asseoir
                                votre marque sur plusieurs marchés.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Ce niveau de budget n’est pas pour tout le monde. Il est réservé à ceux qui voient grand,
                                qui osent et qui sont prêts à bâtir quelque chose d’exceptionnel. Vous avez les cartes
                                en main ; jouez-les avec vision et ambition.</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<section class="pt-5" *ngIf="session.budget > 100000">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Avec un budget de {{session.budget | currency}}
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="lead fs-4 mb-3" style="text-align: justify;">
                            Avec un budget de {{session.budget | currency}}, vous êtes dans la cour des géants. Ce n’est
                            plus seulement un business, mais un projet qui redéfinit votre avenir. Voici comment :
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            Les 3 points clé !
                        </h3>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <p class="fw-bolder text-black mb-0">Créez une marque globale qui inspire confiance et
                                génère des millions de revenus annuels.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Maîtrisez des Incoterms avancés pour gérer des flux
                                complexes sur plusieurs continents.</p>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <p class="fw-bolder text-black mb-0">Mettez en place des partenariats stratégiques avec des
                                distributeurs pour conquérir de nouveaux marchés.</p>
                        </div>
                    </div>

                    <div class="col-md-9 text-center">
                        <blockquote>
                            <p>Vous avez les moyens de transformer un capital en un empire. Mais chaque grand projet
                                commence par une première étape stratégique. Êtes-vous prêt à laisser une empreinte sur
                                le marché mondial ?</p>
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>


<app-simulator-footer></app-simulator-footer>