
<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>

<!-- <section id="content" *ngIf="tokenValid === true">
    <div class="content-wrap">
        <div class="section text-center bg-white">
            <div class="container py-5">
                <div class="promo-users">
                    <img src="assets/images/1.jpg" alt=".." class="square square-lg rounded-circle">
                    <img src="assets/images/1.jpg" alt=".." class="square square-md rounded-circle">
                    <img src="assets/images/1.jpg" alt=".." class="square square-md rounded-circle">
                    <img src="assets/images/1.jpg" alt=".." class="square square-lg rounded-circle">
                    <img src="assets/images/1.jpg" alt=".." class="square square-lg rounded-circle">
                </div>
                <div class="row justify-content-center">
                    <div class="col-md-10">
                        <img src="assets/images/logo.jpg">
                        <p class="text-uppercase fw-semibold mb-4 ls-1 font-primary text-black">Confirmation de votre achat</p>
                        <h1 class="display-5 fw-bold">Votre exemplaire de l'ebook a été envoyé à votre adresse email.</h1>                 
                        <p class="op-07 mw-xs mx-auto mb-5">Cliquez sur le bouton ci-dessous pour télécharger votre exemplaire </p>
                        <a *ngIf="downloadButton === true" style="background-color: #c7ac97;" (click)="getEbookFile()" class="button button-xlarge rounded-pill fw-bold px-5">Télécharger le ebook</a>
                        <a *ngIf="downloadButtonLoading === true" style="background-color: #c7ac97;"  class="button button-xlarge rounded-pill fw-bold px-5"><img src="assets/images/loading.gif" width="20px"> </a>                        
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->


<section class="pt-5" *ngIf="tokenValid === true">
    <div class="content-wrap py-10">
        <div class="page-section">
            <div class="container">
                <div class="clear"></div>
                <div class="text-center">
                    <img src="assets/images/logo.jpg">
                    <h6 class="display-6 fw-bolder ls1 mb-3 position-relative">                    
                        <span [ngStyle]="{'background-color': themeColor }" style="color: white !important;">Confirmation de ton achat !</span>
                         <br>Ton exemplaire  a été envoyé à ton adresse email.<br> Clique sur le bouton ci-dessous pour le télécharger.<br>                        
                    </h6>
                    <p class="lead">
                        Cliquez sur le bouton ci-dessous pour télécharger votre exemplaire</p>
                    <br>                                      
                    <a *ngIf="downloadButton === true" (click)="getEbookFile()" [ngStyle]="{'background-color': themeColor }" style="color: white !important;" class="button button-large button-circle fw-bolder pointer"><i class="bi-cloud-download"></i>Télécharger mon exemplaire </a>                                        
                    <a *ngIf="downloadButtonLoading === true" [ngStyle]="{'background-color': themeColor }" style="color: white !important;" class="button button-large button-circle fw-bolder pointer"><img src="assets/images/loading.gif" width="20px"></a>                                                      
                </div>
            </div>
        </div>
    </div>
</section>

<app-simulator-footer *ngIf="tokenValid === true"></app-simulator-footer>

<section id="content" *ngIf="expired === true">
    <div class="content-wrap">
        <div class="section text-center bg-white">
            <div class="container py-5">                
                <div class="row justify-content-center">
                    <div class="col-md-4">                                                
                        <p class="fw-bold mb-0">
                            Le lien a expiré
                        </p>
                        <p class="mb-0">
                            Ce lien a expiré. Ce qui signifie que votre paiement a déjà été traité ou que votre session a expiré.
                        </p>                                                
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content" *ngIf="errorToken === true">
    <div class="content-wrap">
        <div class="section text-center bg-white">
            <div class="container py-5">                
                <div class="row justify-content-center">
                    <div class="col-md-4">                                                
                        <p class="fw-bold mb-0">
                            Quelque chose s'est mal passé. 
                        </p>
                        <p class="mb-0">
                            La page que vous recherchiez n'a pas pu être trouvée. Veuillez vérifier l'URL ou contacter le marchand.
                        </p>                                                
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


