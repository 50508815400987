<div *ngIf="error === true" class="row align-items-stretch g-4 center mb-5">
    <div class="alert alert-danger" role="alert">
        Une erreur serveur s'est produite. Veuillez réessayer plus tard. Merci pour votre patience.
    </div>
</div>


<section id="slider" class="slider-element  scroll-detect include-header bg-contrast-100 scroll-detect-inview "
    style=" margin-bottom: -50px;">
    <div class="py-6 mt-xl-6 position-relative z-2">
        <div class="container">
            <div class="row align-items-center justify-content-center col-mb-80 mb-0 section-video-vsl">
                <div class="col-12 text-center pt-0 pb-0">
                    <h1 class="mb-4 display-4">
                        <span class="mb-4 fw-bolder text-black">Le secret des pros pour une déco
                            réussie… à portée de main !</span>
                    </h1>
                    <div class="wistia_responsive_padding mb-0" style="padding:56.25% 0 0 0;position:relative;">
                        <div class="wistia_responsive_wrapper"
                            style="height:100%;left:0;position:absolute;top:0;width:100%;">
                            <div class="wistia_embed wistia_async_zmlkvt8b1i seo=true videoFoam=true"
                                style="height:100%;position:relative;width:100%">
                                <div class="wistia_swatch"
                                    style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;">
                                    <img src="https://fast.wistia.com/embed/medias/zmlkvt8b1i/swatch"
                                        style="filter:blur(5px);height:100%;object-fit:contain;width:100%;" alt=""
                                        aria-hidden="true" onload="this.parentNode.style.opacity=1;" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span class="pt-0 mb-3 fw-bolder" style="color: red;"><i style="color: red;" class="uil-volume-up tada slower infinite animated h1"></i> Assurez-toi que le son est activé.</span>
                </div>
                <div class="col-10 center">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-5 position-relative d-none d-md-block text-black">
                        Vous avez plein d’idées pour votre intérieur, mais rien ne s’assemble
                        comme vous le souhaitez ?
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-5 position-relative d-block d-md-none text-black">
                        Vous avez plein d’idées pour votre intérieur, mais rien ne s’assemble
                        comme vous le souhaitez ?
                    </h6>
                    <div class="row">
                        <div class="col-lg-12" style="text-align: left;">
                            <div>
                                <input id="checkbox-4" class="checkbox-style" name="checkbox-4" type="checkbox" checked>
                                <label for="checkbox-4" class="checkbox-style-1-label fw-bolder">Vous passez des
                                    heures à
                                    chercher l’inspiration sur Pinterest ou Instagram,
                                    mais tout reste flou.
                                </label>
                            </div>
                            <div>
                                <input id="checkbox-5" class="checkbox-style" name="checkbox-5" type="checkbox">
                                <label for="checkbox-5" class="checkbox-style-1-label fw-bolder">Chaque décision –
                                    couleur,
                                    meuble, agencement – devient un casse-tête sans
                                    fin.
                                </label>
                            </div>
                            <div>
                                <input id="checkbox-6" class="checkbox-style" name="checkbox-6" type="checkbox">
                                <label for="checkbox-6" class="checkbox-style-1-label fw-bolder">Résultat : des
                                    achats
                                    impulsifs, des choix mal assortis, et un intérieur qui
                                    manque de cohérence.</label>
                            </div>
                            <div>
                                <input id="checkbox-7" class="checkbox-style" name="checkbox-7" type="checkbox">
                                <label for="checkbox-7" class="checkbox-style-1-label fw-bolder">Vous vous sentez
                                    submergé(e), sans méthode claire pour avancer et
                                    concrétiser vos envies.
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="text-center mb-5 section-simulator-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-4 position-relative d-none d-md-block text-black mb-5">
                        Et si vous aviez enfin une solution pour organiser vos idées et transformer vos inspirations en
                        une déco harmonieuse
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-4 position-relative d-block d-md-none text-black">
                        Et si vous aviez enfin une solution pour organiser vos idées et transformer vos inspirations en
                        une déco harmonieuse
                    </h6>
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>



    <div class="position-absolute w-100 h-100 top-0 start-0 z-1 py-0">
        <div class="d-flex px-4 w-100 mt-6 pt-5">
            <div class="position-relative w-50 intro-hero-images-section">
                <div class="position-relative d-flex fadeInDownSmall animated" style="width:35%;left:1%;"
                    data-animate="fadeInDownSmall">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                        data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Classic Demo"
                        class="rounded lazy w-50 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute fadeInRightSmall animated" style="width:65%;right:-14.5%;top:-50%;"
                        data-animate="fadeInRightSmall" data-delay="550">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Speaker Demo"
                            class="rounded lazy ms-4 shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInLeft animated" style="width:37%;left:-18%;"
                    data-animate="fadeInLeft" data-delay="250">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                        data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Recipes Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute zoomIn animated" style="width: 50%;right: -57%;top: -9%;"
                        data-animate="zoomIn" data-delay="800">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                            data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="App Landing Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(var(--cnvs-scroll-end)*30%),calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                    <div class="position-absolute zoomIn animated" style="width: 48%;right: -55%;top: 51.5%;"
                        data-animate="zoomIn" data-delay="1000">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Yoga Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(var(--cnvs-scroll-end)*50%),calc(var(--cnvs-scroll-end)*200%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInUp animated" style="width:38%;left: 3%;"
                    data-animate="fadeInUp" data-delay="500">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                        data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="Supplements Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                </div>
            </div>
            <div class="position-relative w-50 intro-hero-images-section d-flex flex-column align-items-end">
                <div class="position-relative d-flex fadeInDownSmall animated" style="width:35%;right:1%;"
                    data-animate="fadeInDownSmall">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                        data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Conference Demo"
                        class="rounded lazy w-50 shadow ms-auto entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute fadeInLeftSmall animated" style="width: 65%;left:-30%;top:-50%;"
                        data-animate="fadeInLeftSmall" data-delay="550">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                            data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="Freelancer Demo"
                            class="rounded lazy shadow ms-4 w-100 entered lazy-loaded"
                            style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInRight animated" style="width:37%;right:-18%;"
                    data-animate="fadeInRight" data-delay="250">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                        data-src="images/intro/niche/new/cleaner.jpg" alt="Cleaner Demo"
                        class="rounded lazy w-100 shadow ms-auto entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                    <div class="position-absolute zoomIn animated" style="width: 50%;left: -57%;top: -9%;"
                        data-animate="zoomIn" data-delay="800">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Furniture Demo"
                            class="rounded lazy w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(-1*var(--cnvs-scroll-end)*30%),calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                    <div class="position-absolute zoomIn animated" style="width: 48%;left: -55%;top: 51.5%;"
                        data-animate="zoomIn" data-delay="1000">
                        <img width="221" height="159" src="assets/images/ebooks/ebook04/classic.jpg"
                            data-src="assets/images/ebooks/ebook04/classic.jpg" alt="Skincare Demo"
                            class="rounded lazy shadow w-100 entered lazy-loaded"
                            style="transform:translate3d(calc(-1*var(--cnvs-scroll-end)*50%),calc(var(--cnvs-scroll-end)*200%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                            data-ll-status="loaded">
                    </div>
                </div>

                <div class="position-relative mt-4 fadeInUp animated" style="width:38%;right: 3%;"
                    data-animate="fadeInUp" data-delay="500">
                    <img width="221" height="159" src="assets/images/ebooks/ebook04/classic-1.jpg"
                        data-src="assets/images/ebooks/ebook04/classic-1.jpg" alt="SEO Demo"
                        class="rounded lazy w-100 shadow entered lazy-loaded"
                        style="transform:translate3d(0,calc(var(--cnvs-scroll-end)*100%),0);opacity:calc(var(--cnvs-scroll-ratio)*1);"
                        data-ll-status="loaded">
                </div>
            </div>
        </div>
    </div>


</section>




<section class="mockup-section">
    <div class="content-wrap">
        <div class="container">

            <div class="text-center mb-6">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit
                </h6>
                <p class="lead pt-3">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
                    et
                    dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
                    aliquip
                    ex ea commodo consequat.
                </p>
            </div>

            <div class="row gallery-categories gutter-20 mockup-section-gallery">
                <div class="col-md-12">
                    <div
                        style="background: url('assets/images/ebooks/ebook06/book.png') no-repeat center center; background-size: cover; height: 450px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook06/book-01.png') no-repeat center center; background-size: cover; height: 250px;">
                    </div>
                </div>

                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook06/book-03.png') no-repeat 50% 40%; background-size: cover; height: 250px;">
                    </div>
                </div>
                <div class="col-md-4">
                    <div
                        style="background: url('assets/images/ebooks/ebook06/book-02.png') no-repeat 100% 100%; background-size: cover; height: 250px;">
                    </div>
                </div>
            </div>
            <div class="col-12 center pt-5">
                <a (click)="scrollToElement('section-pricing')"
                    class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
            </div>
        </div>

    </div>
</section>



<section class="section-programme">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center section-programme-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Ce que vous allez gagner avec ce Guide
                        <br />Voici ce qui t’attend :
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Ce que vous allez gagner avec ce Guide
                        <br />Voici ce qui t’attend :
                    </h6>
                </div>
                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">
                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #B09079;"
                            data-bs-theme="dark">
                            <h3 class="mb-3 display-6 fw-bold"><b>Une newsletter mensuelle inspirante pour t'inspirer
                                    dans ta tranformation interieur.</b></h3>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <h3 class="mb-3 display-7 fw-bold"><b> (Chapitre 1) <br>Clarté dans vos idées </b></h3>
                            <p class="lead mb-0">Identifiez votre style unique et comprenez les bases pour créer un
                                projet déco cohérent, sans confusion ni hésitation.

                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/presentiel.png" data-animate="tada slower infinite" width="100px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>(Chapitre 2) <br>Une méthode simple et efficace </b>
                            </h3>
                            <p class="lead mb-0">Un guide pas à pas qui vous accompagne dans chaque étape, de
                                l’inspiration à la réalisation de votre planche d’ambiance.

                            </p>
                        </div>
                    </div>
                </div>

                <div class="row justify-content-center col-mb-50 align-items-stretch pt-5">

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>(Chapitre 3) <br>Économies importantes </b>
                            </h3>
                            <p class="lead mb-0">Plus besoin de gaspiller du temps ou de l’argent avec des erreurs
                                coûteuses. Vous ferez les bons choix dès le départ.
                            </p>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg " data-bs-theme="dark">
                            <h3 class="mb-3 display-7 fw-bold"><b>(Chapitre 4) <br>Confiance en vous</b></h3>
                            <p class="lead">Fini le stress des décisions déco ! Avec une vision claire et une
                                planche d’ambiance bien préparée, chaque choix devient naturel et
                                évident.
                            </p>
                            <!-- <img src="images/replay.png" data-animate="tada slower infinite" width="100px" class="center tada slower infinite animated" alt="Feature Icon"> -->
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #F4F1EC;">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold text-black"><b>(Chapitre 5) <br>Un intérieur qui VOUS
                                    ressemble :</b>
                            </h3>
                            <p class="lead text-black">Transformez votre espace en un lieu harmonieux, qui raconte votre
                                histoire et reflète votre personnalité.

                            </p>
                        </div>
                    </div>

                </div>
                <div class="row justify-content col-mb-50 align-items-stretch pt-5">


                    <div class="col-lg-4 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #F4F1EC;">
                            <!-- <img src="images/pdf.png" data-animate="tada slower infinite" width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated"> -->
                            <h3 class="mb-3 display-7 fw-bold"><b>(Chapitre 6) <br>Accessible à tous</b>
                            </h3>
                            <p class="lead mb-0">Pas besoin d’être un professionnel ou d’avoir des connaissances en
                                décoration. Ce guide a été conçu pour être compris et appliqué par
                                tout le monde.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="py-5 text-center bg-contrast-100 border-top border-bottom border-contrast-200"
        data-class="dark:bg-contrast-200 dark:border-contrast-300">
        <div class="container">
            <h2 class="mb-0"><strong class="text-black">Une Newsletter à vie + le Book + le Workbook</strong>
                <!-- <em class="fw-bold">Lifetime Free</em> for New Demos, Features, Fixes &amp; Updates. -->
            </h2>
            <div class="pt-3">
                <a (click)="scrollToElement('section-pricing')"
                    class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                        class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
            </div>
        </div>
    </div>
</section>

<section>
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block text-black mb-5">
                        On te présente une planche d'ambiance
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none text-black">
                        On te présente une planche d'ambiance
                    </h6>
                </div>

                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <img src="assets/images/ebooks/ebook06/mood.png" class="mb-4 pt-2 d-none d-md-block">
                <!-- Contenu visible uniquement sur les smartphones -->
                <img src="assets/images/ebooks/ebook03/mood-port.png" class="mb-2 pt-2 d-block d-md-none">



                <div class="row align-items-stretch justify-content-center center">
                    <div class="col-12">
                        <span class="fw-bolder">Une planche d’ambiance est l’outil indispensable pour réussir votre
                            projet
                            déco, utilisé par les professionnels avant tout achat ou travaux. C’est une
                            représentation visuelle qui rassemble tous les éléments de votre décoration
                            et sert de boussole pour prendre les bonnes décisions.
                            <br>
                            <i>Alors, pourquoi ne pas imaginer la
                                version de Melissa qui propulsera ton projet vers de nouveaux horizons ?</i></span>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="gradient-section" style="background-color: #F4F1EC;">
    <div class="container">
        <div class="row justify-content-center">

            <div class="text-center section-programme-book-workbook-title pt-5">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                    Pourquoi en avez-vous besoin ?
                </h6>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                    Pourquoi en avez-vous besoin ?
                </h6>
            </div>

            <div class="col-xl-8 col-lg-8 text-center ">
                <p class="mb-5 text-black display-7 lead">
                    Avec une planche d’ambiance, vous évitez les achats impulsifs et les erreurs
                    coûteuses.
                    <br>
                    Avant de peindre un mur ou d’acheter un meuble, votre planche d’ambiance
                    sera votre guide pour créer un intérieur parfaitement aligné avec vos goûts et
                    vos besoins.
                    Arrêtez de vous perdre dans vos inspirations, organisez vos idées et
                    concrétisez enfin votre vision déco.


                    Elle vous permet de :
                </p>
            </div>
            <div class="container">
                <div class="container">
                    <div class="row justify-content-center col-mb-50 align-items-stretch">
                        <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                            <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark"
                                style="background-color: #FDD8C1;" data-bs-theme="dark">
                                <h3 class="mb-3 display-6 fw-bold text-black"><b>Avoir une vision claire de votre projet
                                        avant de commencer.
                                    </b>
                                </h3>
                                <img src="assets/images/ebooks/ebook06/verifier.png" data-animate="tada slower infinite"
                                    width="80px" alt="Feature Icon" class="tada slower infinite animated">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                            <div class="d-flex flex-column p-4 rounded-3 shadow-lg" style="background-color: #E9E5E5;">
                                <img src="assets/images/ebooks/ebook06/verifier.png" data-animate="tada slower infinite"
                                    width="80px" alt="Feature Icon" class="mb-5 tada slower infinite animated">
                                <h3 class="mb-3 display-6 fw-bold text-black"><b>Prendre des décisions réfléchies pour
                                        éviter les incohérences.
                                    </b>
                                </h3>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="container">
                    <div class="row justify-content-center col-mb-50 align-items-stretch pt-5 mb-5">
                        <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                            <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark"
                                style="background-color: #E9E5E5;" data-bs-theme="dark">
                                <img src="assets/images/ebooks/ebook06/verifier.png" data-animate="tada slower infinite"
                                    width="80px" class="center tada slower infinite animated" alt="Feature Icon">
                                <h3 class="mb-3 display-6 fw-bold text-black"><b>Économiser du temps et de l’argent en
                                        planifiant chaque étape.
                                    </b>
                                </h3>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6 d-flex align-items-stretch">

                            <div (click)="scrollToElement('section-pricing')"
                                class="pointer d-flex flex-column p-4 rounded-3 shadow-lg"
                                style="background-color: #B09079;">
                                <img src="assets/images/ebooks/ebook06/navette.png" data-animate="tada slower infinite"
                                    width="90px" alt="Feature Icon" class="mb-5 tada slower infinite animated">
                                <h3 class="mb-3 display-6 fw-bold text-white"><b>Je passe à l’action avec "LE GUIDE DECO
                                        DESIGN"</b></h3>


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="section-programme-book-workbook">
    <div class="content-wrap">
        <div class="page-section">
            <div class="container">
                <div class="text-center section-programme-book-workbook-title">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h6 class="display-5 fw-bolder ls1 mb-1 position-relative d-none d-md-block">
                        Je vous accompagne dans la
                        transformation de votre intérieur
                    </h6>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h6 class="display-6 fw-bolder ls1 mb-1 position-relative d-block d-md-none">
                        Je vous accompagne dans la
                        transformation de votre intérieur
                    </h6>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">Qui je suis ?</h2>
                    <p class="lead mb-5">Je suis Nadia, décoratrice d’intérieur et fondatrice d’Essika Design,
                        une entreprise où chaque projet est conçu pour transformer un
                        espace en une véritable extension de votre personnalité."</p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">Qui je suis ?</h2>
                    <p class="lead mb-0">Je suis Nadia, décoratrice d’intérieur et fondatrice d’Essika Design, une
                        entreprise où chaque projet est conçu pour transformer un espace en une véritable extension de
                        votre personnalité."</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast" data-delay="300" style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>


<section style="margin-top: -80px;">
    <div class="content-wrap">
        <div class="container">
            <div class="heading-block mb-5 text-center border-bottom-0 pt-3">
                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                <h2 class="text-transform-none fw-bolder d-none d-md-block">
                    TÉMOIGNAGES
                </h2>
                <!-- Contenu visible uniquement sur les smartphones -->
                <h3 class="text-transform-none fw-bolder d-block d-md-none">
                    TÉMOIGNAGES
                </h3>
            </div>

            <div class="row user-testimonials">
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">"Je pensais que faire appel à un décorateur était la seule solution pour
                                réussir ma déco. Mais avec ce guide, j’ai appris à tout faire moi-même, étape
                                par étape, sans erreurs coûteuses. C’est une vraie économie, et le résultat est
                                bluffant !</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Amélie D.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">Je n’avais aucune idée de par où commencer pour ma déco. Ce guide m’a
                                appris les bases et m’a guidée avec des étapes simples et faciles à suivre.
                                Même en étant novice, j’ai réussi à créer un intérieur qui me correspond
                                parfaitement."
                                </p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Nadia K.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">J’étais complètement perdue avec toutes les options et styles disponibles. Ce
                                guide m’a donné une méthode claire pour organiser mes idées et passer à
                                l’action. Le résultat ? Une déco harmonieuse et un sentiment
                                d’accomplissement."</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Julie T.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12 mb-4">
                    <div class="card shadow-sm overflow-hidden">
                        <div class="card-body">
                            <p class="text-medium mb-4 text-muted">J’avais toujours rêvé d’un intérieur qui reflète ma personnalité, mais je ne
                                savais pas par où commencer. Grâce à ce guide, j’ai pu clarifier mes idées et
                                créer une planche d’ambiance qui m’a guidée dans chaque décision.
                                Aujourd’hui, ma maison me ressemble vraiment !"</p>
                            <div class="d-flex mt-2 align-items-center justify-content-between">
                                <h5 class="fs-6 mb-0 op-08">Sarah M.</h5>
                                <div class="testimonials-rating">
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                    <i class="color-start-green bi-star-fill star-color"></i>
                                </div>
                            </div>
                        </div>
                        <div class="bg-icon bi-star-fill op-03"></div>
                    </div>
                </div>
       
            </div>            
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-5">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">📘 Un guide complet en PDF</h2>
                    <p class="lead mb-5">Un guide détaillé, riche en conseils pratiques et étapes simples,
                        pour réussir chaque aspect de votre projet déco.
                        .</p>


                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">📘 Un guide complet en PDF</h2>
                    <p class="lead mb-0">Lorem, ipsum dolor sit amet consectetur adipisicing elit.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast" data-delay="300" style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div> -->
                        </div>

                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">✅ Des exercices pratiques</h2>
                    <p class="lead mb-5">Des outils concrets pour organiser vos idées et clarifier vos choix.</p>


                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">✅ Des exercices pratiques</h2>
                    <p class="lead mb-0">Des outils concrets pour organiser vos idées et clarifier vos choix.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast" data-delay="300" style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Desktop version -->
<section class="d-none d-md-block">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>

                            <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="200"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>

                            <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="300"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">🎨 Des astuces d’expert </h2>
                    <p class="lead mb-5">Des conseils professionnels pour éviter les erreurs et aller à
                        l’essentiel, même si vous débutez.</p>

                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>

<!-- Mobile version -->
<section class="d-block d-md-none margin-content-ebook-mobile">
    <div class="content-wrap">
        <div class="container mw-md">
            <div class="row align-items-center justify-content-between col-mb-50">
                <div class="col-md-6 center">
                    <div class="text-uppercase ls-2 mb-4 op-04">Template</div>
                    <h2 class="display-4 mb-4 fw-bolder">🎨 Des astuces d’expert</h2>
                    <p class="lead mb-0">Des conseils professionnels pour éviter les erreurs et aller à
                        l’essentiel, même si vous débutez.</p>

                    <!-- <a href="#" class="btn btn-dark px-4 py-3 d-flex w-50">Get Started <i class="bi-arrow-right ms-auto"></i></a> -->
                </div>

                <div class="col-md-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn animated" data-animate="zoomIn"
                                style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 330px;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 ms-auto zoomIn fast animated" data-animate="zoomIn fast" data-delay="200" style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 300px; width: 85%;">
                            </div> -->
                        </div>
                        <div class="col-6">
                            <div class="shadow-sm rounded zoomIn fast animated" data-animate="zoomIn fast"
                                data-delay="100"
                                style="background: url('assets/images/ebooks/ebook04/4.jpg') no-repeat center center / cover; min-height: 200px; width: 80%;">
                            </div>
                            <!-- <div class="shadow-sm rounded mt-4 zoomIn fast animated" data-animate="zoomIn fast" data-delay="300" style="background: url('assets/images/ebooks/ebook04/1.jpg') no-repeat center center / cover; min-height: 300px;">
                            </div> -->
                        </div>
                    </div>
                </div>
                <div class="col-md-12 center">
                    <a (click)="scrollToElement('section-pricing')"
                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                </div>
            </div>
        </div>
    </div>
</section>




<!-- Desktop version -->
<section id="section-pricing" class="section-price">
    <div class="content-wrap">
        <div class="container">

            <div class="row justify-content-between">

                <div class="col-xl-4 mb-5 mb-xl-0">
                    <div class="row mx-0 h-100 rounded-5 p-5" style="background-color: #B09079">
                        <div class="col-12 col-lg-12 col-xl-12">
                            <h3 class="h3 mb-4">Télécharger le guide deco 
                            </h3>
                            <p>Apprenez à transformer votre intérieur en un
                                espace unique qui reflète vos goûts et votre
                                personnalité, étape par étape.</p>
                        </div>
                        <img src="assets/images/ebooks/ebook06/book-05.png" alt="Image"
                            class="mt-xl-auto col-12 col-lg-4 col-xl-12">
                    </div>
                </div>

                <div class="col-xl-8 d-flex flex-column justify-content-end">
                    <div class="row mb-2">
                        <div class="col-md-8">
                            <h3 class="h1">Une solution
                                <br>accessible à tous !
                            </h3>
                        </div>

                    </div>
                    <div class="row pricing">
                        <div class="col-md-6">
                            <div
                                class="pricing-box rounded-5 pricing-simple p-5 bg-light border text-center text-md-start">
                                <div class="d-flex flex-column flex-lg-row justify-content-between mb-4">
                                    <div>
                                        <h3 class="mb-0 h3" style="line-height: 1">12€</h3>
                                        <small>Paiement unique</small>
                                        <hr>
                                        <h3 class="mb-3">Book + kit deco <span style="color: #F8F9FA;"><br>.</span>
                                        </h3>
                                        <div class="line border-width-4 border-dark p-0 m-0 w-25 mx-auto mx-md-0"></div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <a (click)="checkout('starter')" *ngIf="checkoutButton === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                                    <a *ngIf="checkoutButtonLoading === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><img
                                            src="assets/images/loading.gif" width="20px"></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-md-6">
                            <div
                                class="pricing-box rounded-5 pricing-simple p-5 bg-light border text-center text-md-start">
                                <div class="d-flex flex-column flex-lg-row justify-content-between mb-4">
                                    <div>
                                        <h3 class="mb-0 h3" style="line-height: 1">27€</h3>
                                        <small>Paiement unique</small>
                                        <hr>
                                        <h3 class="mb-3">Book + kit deco + La Newsletter mensuel</h3>
                                        <div class="line border-width-4 border-dark p-0 m-0 w-25 mx-auto mx-md-0"></div>
                                    </div>
                                </div>
                                <div class="text-center">
                                    <a (click)="checkout('Premium')" *ngIf="checkoutButton === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><i
                                            class="bi-arrow-down-right-circle-fill"></i>Télécharger</a>
                                    <a *ngIf="checkoutButtonLoading === true"
                                        class="button  btn-lg button-circle button-green fw-bolder call-to-action-background-color"><img
                                            src="assets/images/loading.gif" width="20px"></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section style="margin-top: 30px;">
    <div class="content-wrap py-0">
        <div id="section-faqs" class="page-section my-5">
            <div class="container">
                <div class="row justify-content-between position-relative">
                    <div class="col-lg-5">
                        <h3 class="display-3 ls-n-3 fw-bolder mb-3">
                            <span class="color-important-word">Questions</span>
                            Fréquemment Posées
                        </h3>
                        <img src="assets/images/ebooks/ebook06/book-07.png" alt="...">
                    </div>
                    <div class="col-lg-6 bg-contrast-0 p-5 rounded-xxl pt-6">
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Combien de volumes contient le guide ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Notre "Guide ultime pour lancer son business" est composé de deux volumes :
                                - Le premier volume couvre les bases de la création d'entreprise.
                                - Le deuxième volume est entièrement consacré à l'intelligence artificielle et à son
                                application dans ton business.
                            </div>
                        </div>

                        <div class="line border-contrast-300 my-4"></div>

                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Quelle est la durée du plan d'action
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le guide propose un plan d'action sur 30 jours. Cependant, tu peux avancer à ton propre
                                rythme et prendre le temps nécessaire pour bien assimiler chaque étape.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce que le guide est remboursable ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Non, en raison de la nature numérique de ce produit, les achats ne sont pas
                                remboursables. Nous sommes convaincus que tu trouveras une valeur énorme dans notre
                                guide.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Comment vais-je recevoir le guide après l'achat ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Après ton paiement, tu recevras directement par email les deux volumes de notre guide.
                                Vérifie bien ton adresse email lors de la commande pour t'assurer de recevoir les
                                documents sans problème.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Qui puis-je contacter en cas de questions ou de problèmes ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Si tu as des questions ou rencontres des problèmes, n'hésite pas à nous contacter à
                                l'adresse suivante : contact&#64;cestnow.com. Nous sommes là pour t'aider et répondre à
                                toutes tes questions.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Est-ce que le guide est adapté à tous les niveaux d'entrepreneurs ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Oui, le guide est conçu pour s'adapter aux besoins des entrepreneurs débutants,
                                intermédiaires, et avancés. Chaque section du guide est structurée pour offrir des
                                conseils et des stratégies adaptés à ton niveau actuel.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Combien de temps faut-il pour voir les premiers résultats avec ce guide ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le temps pour voir les résultats peut varier en fonction de ton engagement et de ton
                                rythme de travail. Cependant, en suivant les étapes du plan d'action de 30 jours, tu
                                devrais commencer à voir des progrès significatifs dans ton projet entrepreneurial.
                            </div>
                        </div>
                        <div class="line border-contrast-300 my-4"></div>
                        <div class="toggle">
                            <div class="toggle-header">
                                <div class="toggle-title">
                                    Puis-je partager le guide avec d'autres personnes ?
                                </div>
                                <div class="toggle-icon">
                                    <i class="toggle-closed fa-solid fa-plus"></i>
                                </div>
                            </div>
                            <div class="toggle-content" style="display: none;">
                                Le guide est destiné à un usage individuel et n'est pas conçu pour être partagé.
                                Cependant, si tu connais quelqu'un qui pourrait bénéficier du guide, nous t'encourageons
                                à lui recommander d'en acheter un exemplaire pour lui-même.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="clear"></div>
    </div>
</section>


<app-simulator-footer></app-simulator-footer>