import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { Ebook05Session } from "../../session/ebook05.session";
import { environment } from '../../../environments/environment';


@Component({
  selector: 'app-product-ebook05',
  templateUrl: './product-ebook05.component.html',
  styleUrls: ['./product-ebook05.component.css']
})
export class ProductEbook05Component {

  // Session de simulation, pour stocker et récupérer les données de l'utilisateur
  session: any = {};  
  error: any;
  errorBudget: any;

  constructor(private ebook05Session: Ebook05Session, private router: Router) {
  }

  // Fonction exécutée lors de l'initialisation du composant
  ngOnInit(): void {
    // Initialisation de la session de simulation
    this.ebook05Session.initializeSimulator(environment.products.ebooks.ebook05.sessionId);
    this.session = this.ebook05Session.getItem(environment.products.ebooks.ebook05.sessionId);

    // Si la session est nulle, on la charge et on la sauvegarde
    if (this.session == null) {
      this.ebook05Session.loadState(environment.products.ebooks.ebook05.sessionId);
      this.ebook05Session.saveState(environment.products.ebooks.ebook05.sessionId);
    }

    // Récupération de la session mise à jour
    this.session = this.ebook05Session.getItem(environment.products.ebooks.ebook05.sessionId);
    console.log(this.session);
  }

  numberOnly(event: any): boolean {

    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  // Méthode pour formater les nombres au format européen
  formatNumber(value: number): string {
    return new Intl.NumberFormat('fr-FR', {
      minimumFractionDigits: 0,
      maximumFractionDigits: 2,
    }).format(value);
  }

  plusBudget() {
    // const parsedValue = parseInt(this.budget, 10);
    // this.budget += 500;
    // this.budget = (parsedValue + 500).toString();
    this.error = null; // Réinitialiser les erreurs
    this.session.budget += 500;
    this.ebook05Session.setItem(environment.products.ebooks.ebook05.sessionId, this.session);
  }

  // Décrémenter le budget de 500
  // minusBudget() {
  //   if (this.budget - 500 < 0) {
  //     this.error = 'Le budget ne peut pas être négatif.';
  //   } else {
  //     this.error = null;
  //     this.budget -= 500;
  //   }
  // }

  // Décrémenter le budget de 500 (avec minimum de 500)
  minusBudget() {
    if (this.session.budget - 500 < 500) {
      this.error = 'Le budget ne peut pas être inférieur à 500.';
      this.errorBudget = true;
      setTimeout(() => {
        this.errorBudget = false;
      }, 2500);
    } else {
      this.error = null;
      this.session.budget -= 500;
      this.ebook05Session.setItem(environment.products.ebooks.ebook05.sessionId, this.session);
    }
  }

  // // Autorise uniquement les chiffres et supprime les caractères non valides
  // validateBudgetInput(event: any) {
  //   alert('here');
  //   const inputValue = event.target.value.replace(/\s+/g, '').replace(/[^0-9]/g, '');
  //   const parsedValue = parseInt(inputValue, 10);

  //   if (isNaN(parsedValue) || parsedValue < 500) {
  //     this.error = 'Veuillez entrer un budget valide.';
  //     this.budget = 500; // Valeur par défaut en cas d'entrée invalide
  //   } else {
  //     this.error = null;
  //     this.budget = parsedValue; // Mise à jour du budget avec une valeur valide
  //   }
  // }

  // Autorise uniquement les chiffres et supprime les caractères non valides
  validateBudgetInput(event: any) {
    const inputValue = event.target.value.replace(/\s+/g, '').replace(/[^0-9]/g, '');
    const parsedValue = parseInt(inputValue, 10);
    this.errorBudget = false;
    if (isNaN(parsedValue) || parsedValue < 500) {
      this.error = 'Veuillez entrer un budget valide.';
      // this.budget = 500; // Valeur par défaut
      // this.errorBudget = true;
      // setTimeout(() => {
      //   this.errorBudget = false;
      //   this.budget = 500;
      // }, 2500); 
    } else {
      this.errorBudget = false;
      this.error = null;
      // this.budget = parsedValue; // Mise à jour avec une valeur valide
      this.session.budget = parsedValue;
      this.ebook05Session.setItem(environment.products.ebooks.ebook05.sessionId, this.session);
    }

    // Met à jour la valeur de l'input
    event.target.value = this.session.budget.toString();
  }

  validationBudget() {
   
    if (this.session.budget < 500) {
      this.session.budget = 500;
      this.ebook05Session.setItem(environment.products.ebooks.ebook05.sessionId, this.session);
      this.errorBudget = true;
      setTimeout(() => {
        this.errorBudget = false;
        this.session.budget = 500;
        this.ebook05Session.setItem(environment.products.ebooks.ebook05.sessionId, this.session);
      }, 1500);
    } else {
      this.router.navigateByUrl('/ebook/guide-import-export/00/invest');
    }

  }


}
