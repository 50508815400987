import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { Ebook05Session } from "../../../session/ebook05.session";
import { environment } from '../../../../environments/environment';

@Component({
  selector: 'app-ebook05-step01',
  templateUrl: './ebook05-step01.component.html',
  styleUrls: ['./ebook05-step01.component.css']
})
export class Ebook05Step01Component {

  error: any;
  // Variables pour gérer l'état des cartes sélectionnées
  isCardSelected01: boolean = false;
  isCardSelected02: boolean = false;


  // Session de simulation, pour stocker et récupérer les données de l'utilisateur
  session: any = {};

  constructor(private ebook05Session: Ebook05Session, private router: Router) {
  }

  // Fonction exécutée lors de l'initialisation du composant
  ngOnInit(): void {
    // Récupère la session utilisateur
    this.session = this.ebook05Session.getItem(environment.products.ebooks.ebook05.sessionId);
    console.log(this.session);
    // Si la session est nulle ou si le genre n'a pas été sélectionné, redirige vers l'étape précédente
    // if (this.session === null || this.session.gender.check === false) {
    //   this.router.navigateByUrl('/business-1/simulateur/etape/0');         
    // }

    // // Initialise le pixel Facebook pour le suivi des événements
    // this.facebookService.init(environment.products.simulatorbu01.pixel);
  }

  navigateNextStep(response: string) {

    // Enregistre la réponse de l'utilisateur pour l'étape actuelle dans la session
    this.session.step1.response = response;
    this.session.step1.check = true;

    // Met à jour la session avec les nouvelles informations
    this.ebook05Session.setItem(environment.products.ebooks.ebook05.sessionId, this.session);

    // Met à jour l'état des cartes en fonction de la réponse sélectionnée
    if (response === '01') {
      this.isCardSelected01 = true;
      this.isCardSelected02 = false;
    } else if (response === '02') {
      this.isCardSelected01 = false;
      this.isCardSelected02 = true;
    }

    // Navigation vers l'étape suivante après un délai de 500 millisecondes
    setTimeout(() => {
      this.router.navigateByUrl('/ebook/guide-import-export/02/produit');
    }, 500);

  }

}
