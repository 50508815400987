<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <div class="col-12 col-lg-12">
                        <a routerLink="/ebook/guide-import-export/03/societe"
                            class="button  button-rounded fw-bolder"><i class="bi-arrow-left-circle-fill"></i></a>
                        <a routerLink="/ebook/guide-import-export/03/societe"
                            class="button  button-rounded fw-bolder">3.
                            Société</a>
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>

<!-- réponse oui pour la societe -->
<section class="pt-5" *ngIf="session.step3.response ==='01'">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Parfait ! Tu es déjà en route vers le succès.
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Parfait ! Tu es déjà en route vers le succès.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">
                        <p class="mb-0">
                            Créer ta société est une étape essentielle, et c’est une très bonne nouvelle. Cela signifie
                            que tu es prêt(e) à structurer ton activité, gérer tes importations et développer tes
                            ventes. Mais dans le contexte français, ce n’est pas suffisant. La véritable question est :
                            comment garder ton argent et faire prospérer ton entreprise face à un système fiscal souvent
                            étouffant ?
                        </p>
                        <hr>
                        <p>
                            La France, avec ses impôts élevés et ses charges sociales, peut rapidement transformer ton
                            succès en fardeau si tu n’as pas les bonnes stratégies. C’est là que tout se joue :
                            anticiper, optimiser et protéger tes revenus dès maintenant.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            La Feuille de Route
                        </h3>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Protéger tes bénéfices</h2>
                            <p class="text-black mb-0 pt-4">
                                Mets en place une stratégie fiscale adaptée pour minimiser tes charges tout en restant
                                en règle.
                            </p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">
                                Explore des structures internationales ou des collaborations transfrontalières pour
                                alléger le poids des impôts.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Optimiser tes opérations</h2>
                            <p class="text-black mb-0 pt-4"> Assure-toi que chaque euro dépensé pour tes
                                importations est maximisé grâce à une gestion précise des coûts logistiques et
                                douaniers.
                            </p>
                            <hr class="text-black">
                            <p class="text-black pt-0">
                                Travaille avec des partenaires qui comprennent les subtilités fiscales et
                                réglementaires.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Préparer l’expansion</h2>
                            <p class="text-black mb-0 pt-4"> Pense à diversifier tes activités ou à explorer des
                                marchés moins fiscalisés si ton activité le permet.<br>
                            </p>
                            <hr class="text-black">
                            <p class="text-black">
                                Utilise ton statut de société pour investir intelligemment dans des leviers de
                                croissance (marketing, développement produit, etc.).
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            Créer une société, c’est franchir la première étape. Mais le véritable défi, c’est de
                            garder ce que tu gagnes. Avec les bonnes stratégies fiscales et opérationnelles, tu peux
                            non seulement protéger tes revenus, mais aussi les multiplier. La clé est de penser à
                            long terme et d’agir maintenant pour sécuriser ton futur.
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<!-- réponse oui pour la societe -->
<section class="pt-5" *ngIf="session.step3.response ==='02'">
    <div class="content-wrap py-10">
        <div class="py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <h3 class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        Pas encore de société ? Tu es à la bonne étape pour bien démarrer.
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <h3 class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        Pas encore de société ? Tu es à la bonne étape pour bien démarrer.
                    </h3>
                </div>
                <div class="row align-items-stretch g-4 justify-content-center center mb-5">
                    <div class="col-md-9 text-center">

                        <p class="mb-0">
                            Ne pas avoir encore créé ta société, c’est une opportunité. Pourquoi ? Parce que tu es à
                            l’étape où tu peux réfléchir aux meilleures options pour protéger ton argent et maximiser
                            tes profits. Dans le contexte français, la fiscalité peut devenir un véritable obstacle si
                            elle n’est pas anticipée. Avant de te lancer, il est crucial de structurer ton projet pour
                            éviter les erreurs qui coûtent cher.
                        </p>
                        <hr>
                        <p>
                            La France, avec ses impôts élevés et ses charges sociales, peut rapidement transformer ton
                            succès en fardeau si tu n’as pas les bonnes stratégies. C’est là que tout se joue :
                            anticiper, optimiser et protéger tes revenus dès maintenant.
                        </p>
                        <h3 class="display-5 fw-bolder ls1 mb-3 position-relative">
                            La Feuille de Route
                        </h3>
                    </div>

                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;">
                            <h2 class="text-black fw-bolder mb-0">Prépare ta structure juridique intelligemment</h2>
                            <p class="text-black mb-0 pt-4">
                                En France, les impôts et charges sociales peuvent rapidement étouffer un business.
                            </p>
                            <hr class="text-black">
                            <p class="text-black mb-0 pt-0">
                                Une SASU ou une EURL peut être une bonne option pour commencer, mais réfléchis également
                                à des solutions internationales pour alléger ton poids fiscal.
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Anticipe les charges avant même de vendre</h2>
                            <p class="text-black mb-0 pt-4">Une mauvaise gestion des obligations fiscales peut
                                transformer tes bénéfices en pertes.
                            </p>
                            <hr class="text-black">
                            <p class="text-black pt-0">
                                Étudie les régimes fiscaux et choisis celui qui correspond le mieux à tes objectifs
                                (micro-entreprise, société classique ou solution optimisée).
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-8 col-md-8 d-flex align-items-stretch">
                        <div class="d-flex flex-column p-4 rounded-3 shadow-lg dark" style="background-color: #FFD600;"
                            data-bs-theme="dark">
                            <h2 class="text-black fw-bolder mb-0">Concentre-toi sur la croissance avant tout</h2>
                            <p class="text-black mb-0 pt-4"> Créer une société est une formalité. Ce qui compte, c’est
                                de générer du revenu avec une stratégie claire.<br>
                            </p>
                            <hr class="text-black">
                            <p class="text-black">
                                Une fois que ton business commence à tourner, tu pourras ajuster ta structure pour
                                maximiser tes profits.
                            </p>
                        </div>
                    </div>

                    <div class="col-12 col-sm-12 col-md-9 col-lg-9 col-xl-9 text-center">
                        <blockquote class="blockquote">
                            Créer une société n’est pas un simple papier à remplir. C’est une décision stratégique qui
                            peut faire toute la différence entre un business qui prospère et un projet qui s’effondre
                            sous les charges. Tu es à l’étape idéale pour poser les bonnes bases et faire les choix qui
                            te protégeront à long terme.
                        </blockquote>
                    </div>

                    <div class="col-lg-8 text-center pb-3">
                        <div class="row justify-content-center gutter-20 mx-auto" style="max-width: 600px;">
                            <div class="col-sm-6 col-xl-5">
                                <a (click)="navigateNextStep()" class="button button-large shadow-sm rounded m-0 w-100 fw-bolder"
                                    style="padding: 0.75rem 2rem;"><i class="bi-arrow-down-right-circle-fill"
                                        style="position: relative; top: 1px; margin-right: 5px;"></i> Etape suivante</a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>