import { Component, HostListener, ViewChild, ViewChildren, ElementRef, QueryList } from '@angular/core';
import { EbookService } from '../../services/ebook.service';
import { FacebookService } from '../../services/facebook.service';
import { Router } from "@angular/router";
import { environment } from '../../../environments/environment';

import { interval, Subscription } from 'rxjs';
import { takeWhile } from 'rxjs/operators';

@Component({
  selector: 'app-product-ebook03',
  templateUrl: './product-ebook03.component.html',
  styleUrls: ['./product-ebook03.component.css']
})
export class ProductEbook03Component {
  @ViewChildren('scrollTrack') scrollTracks!: QueryList<ElementRef>;

  // État d'activation/désactivation du block video pour mobile
  showMobileVideo: any = false;

  // État d'activation/désactivation du block video pour desktop
  showDesktopVideo: any = false;

  // Couleur du thème pour le composant, initialisée à #9A42A4
  themeColor: any = '#a50000';

  // Variable de gestion d'erreur lors du processus de paiement
  error: any;

  // État d'activation/désactivation du bouton de paiement
  checkoutButton: any = true;

  // Indicateur de chargement pour le bouton de paiement
  checkoutButtonLoading: any = false;

  // Temps initial pour le compte à rebours (15 minutes en secondes)
  private initialTime = 15 * 60; // 15 minutes in seconds

  // Temps restant pour le compte à rebours
  timeLeft: number = 0;

  // Subscription de l'observable utilisée pour le compte à rebours
  subscription: Subscription | null = null;

  // Heure de départ pour le compte à rebours
  startTime: number | null = null;

  // Indicateur pour afficher ou masquer le compte à rebours
  showCountdown: boolean = true;

  // Prix actuel du produit
  currentPrice: number = 0;

  // Prix initial du produit
  price: number = 97;

  // Prix promotionnel 1 après expiration du compte à rebours
  pricePromo01: number = 27;

  // Prix promotionnel 2 pendant le compte à rebours
  pricePromo02: number = 17.97;

  productPrice: number = 57; // Simulateur prix initial du produit
  dailyCustomers: number = 5; // Simulateur nombre initial de clients par jour
  projectedRevenue: number = 5550; // Simulateur revenu mensuel calculé
  followers: number = 3500; // Simulateur revenu mensuel calculé
  conversionRate: number = 3;

  items = Array.from({ length: 10 }, (_, i) => `Item ${i + 1}`);
  instances = Array.from({ length: 3 }); // 3 conteneurs par exemple
  positions: number[] = [];
  animationFrames: number[] = [];

  constructor(
    private router: Router,
    private ebookService: EbookService,
    private facebookService: FacebookService
  ) { }




  ngOnInit(): void {

    this.loadVideoBasedOnWindowSize();

    // Chargement des scripts externes Wistia
    // this.loadExternalScript('https://fast.wistia.com/embed/medias/skqifk74yy.jsonp');
    // this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');


    // Initialisation des états du bouton de paiement
    this.checkoutButton = true;
    this.checkoutButtonLoading = false;

    // Chargement du compte à rebours
    this.loadCountdown();

    // Initialisation et suivi des événements Facebook
    this.facebookService.init(environment.products.ebooks.ebook03.pixel);
    this.facebookService.track('PageView');
    this.facebookService.sendEventToFacebook(environment.products.ebooks.ebook03.pixel, environment.products.ebooks.ebook03.accessToken, 'PageView');
  }

  ngAfterViewInit(): void {
    this.scrollTracks.forEach((track, index) => {
      this.positions[index] = 0; // Initialiser la position de chaque instance
      this.initializeScroll(track.nativeElement, index);
    });
    // Mettre à jour la couleur des sliders dès le chargement
    this.updateSliderBackground('followers', this.followers, 300, 50000);
    this.updateSliderBackground('conversionRate', this.conversionRate, 0.1, 10);
    this.updateSliderBackground('price', this.productPrice, 10, 500);

    this.updateRevenue();
  }

  initializeScroll(track: HTMLElement, index: number): void {
    const items = Array.from(track.children) as HTMLElement[];

    // Dupliquer les éléments pour un défilement fluide
    items.forEach((item) => {
      const clone = item.cloneNode(true) as HTMLElement;
      track.appendChild(clone);
    });

    const totalWidth = Array.from(track.children).reduce(
      (acc, item: any) => acc + item.offsetWidth + 20, // 20 = gap
      0
    );

    track.style.width = `${totalWidth}px`;

    this.startScroll(track, index);
  }

  startScroll(track: HTMLElement, index: number): void {
    const scroll = () => {
      this.positions[index] -= 4; // Ajuster la vitesse pour chaque instance
      if (Math.abs(this.positions[index]) >= track.scrollWidth / 2) {
        // Remettre à zéro à mi-parcours
        this.positions[index] = 0;
      }
      track.style.transform = `translateX(${this.positions[index]}px)`;
      this.animationFrames[index] = requestAnimationFrame(scroll);
    };

    scroll();
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.animationFrames.forEach((frame) => cancelAnimationFrame(frame));
  }

  updateRevenue(): void {
    // Calcul du revenu mensuel : prix produit * clients quotidiens * 30 jours
    // this.projectedRevenue = this.productPrice * this.dailyCustomers * 30;
    // Calcul du revenu mensuel : abonnés * taux de conversion * prix produit * 30 jours
    
    const dailyConversions = (this.followers * (this.conversionRate / 100)); // Nombre de conversions par jour
    const dailyRevenue = dailyConversions * this.productPrice; // Revenu journalier
    this.projectedRevenue = dailyRevenue * 1; // Revenu mensuel


    this.updateSliderBackground('followers', this.followers, 300, 50000);
    this.updateSliderBackground('conversionRate', this.conversionRate, 0.1, 10); // Ajustez min et max
    this.updateSliderBackground('price', this.productPrice, 10, 500); // Ajustez min et max


  }

  updateSliderBackground(id: string, value: number, min: number, max: number): void {
    const slider = document.getElementById(id) as HTMLInputElement;
    const percentage = ((value - min) / (max - min)) * 100;
    slider.style.setProperty('--value', `${percentage}%`);
  }




  // Ecoute les changements de taille de la fenêtre
  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.loadVideoBasedOnWindowSize();
  }


  // Fonction qui charge la vidéo en fonction de la taille de la fenêtre
  loadVideoBasedOnWindowSize(): void {
    const width = window.innerWidth;
    if (width <= 768) {
      // Si la largeur de la fenêtre est inférieure ou égale à 768px (smartphone ou tablette)
      // this.loadExternalScript('https://fast.wistia.com/embed/medias/mobile_video.jsonp');
      // alert('here smartphone');
      this.loadExternalScript('https://fast.wistia.com/embed/medias/skqifk74yy.jsonp');
      this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');
      this.showMobileVideo = true;
      this.showDesktopVideo = false;

    } else {
      // Si la largeur est supérieure à 768px (desktop)
      this.loadExternalScript('https://fast.wistia.com/embed/medias/qr0jrj6vwg.jsonp');
      this.loadExternalScript('https://fast.wistia.com/assets/external/E-v1.js');
      this.showMobileVideo = false;
      this.showDesktopVideo = true;
    }
  }

  // Fonction pour charger un script externe
  public loadExternalScript(url: string) {
    const body = <HTMLDivElement>document.body;
    const script = document.createElement('script');
    script.innerHTML = '';
    script.src = url;
    script.async = true;
    script.defer = true;
    body.appendChild(script);
  }

  isMobileDevice(): boolean {
    const userAgent = navigator.userAgent.toLowerCase();
    // Vérification si le UserAgent correspond à un mobile
    return /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
  }


  // Fonction pour gérer le processus de paiement
  async checkout(ref: any) {
    // Assignation du prix actuel à l'environnement du produit
    // environment.products.ebooks.ebook03.price = this.currentPrice;
    if (ref === 'starter') {
      // refOffer = 'le-guide-ultime';
      environment.products.ebooks.ebook03.ref = 'guide-faceless-reels-ia';
      environment.products.ebooks.ebook03.price = 27;
    } else {
      // refOffer = 'le-guide-ultime-p';
      environment.products.ebooks.ebook03.ref = 'guide-faceless-reels-ia-p';
      environment.products.ebooks.ebook03.price = 197;
    }
    
    this.checkoutButton = false;
    this.checkoutButtonLoading = true;

    let data = {
      id: environment.products.ebooks.ebook03.id,
      ref: environment.products.ebooks.ebook03.ref,
      price: environment.products.ebooks.ebook03.price,
      product: environment.products.ebooks.ebook03,
      pixel: environment.products.ebooks.ebook03.pixel,
      accessToken: environment.products.ebooks.ebook03.accessToken,
      themeColor: this.themeColor,
      checkoutCancelUrl: environment.ebookCheckoutCancelUrl,
      checkoutSuccessUrl: environment.ebookCheckoutSuccessUrl,
      stripe: environment.stripe
    }

    // Suivi des événements Facebook pour 'AddToCart'
    this.facebookService.init(environment.products.ebooks.ebook03.pixel);
    this.facebookService.track('AddToCart', {
      content_name: environment.products.ebooks.ebook03.ref,
      content_ids: environment.products.ebooks.ebook03.id,
      content_type: 'product',
      value: this.currentPrice,
      currency: environment.products.ebooks.ebook03.devise,
      quantity: 1
    });

    let cartObject = {
      content_name: environment.products.ebooks.ebook03.ref,
      content_ids: environment.products.ebooks.ebook03.id,
      content_type: 'product',
      value: this.currentPrice,
      currency: environment.products.ebooks.ebook03.devise,
      quantity: 1
    }

    this.facebookService.sendEventToFacebook(
      environment.products.ebooks.ebook03.pixel,
      environment.products.ebooks.ebook03.accessToken,
      'AddToCart',
      '',
      cartObject
    );

    // Appel au service de checkout pour redirection vers Stripe
    this.ebookService.checkout(data).subscribe({
      next: (response: any) => {
        /* success callback */
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        window.location.href = response.session.url;
      },
      error: (error: any) => {
        /* error callback */
        this.error = true;
        this.checkoutButton = true;
        this.checkoutButtonLoading = false;
        setTimeout(() => {
          this.error = null;
        }, 3500); // 500 millisecondes = 0.5 seconde       
      }
    });
  }

  // Fonction pour faire défiler la page vers un élément spécifique
  scrollToElement(elementId: string): void {
    const element = document.querySelector(`#${elementId}`);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  // Fonction pour charger le compte à rebours depuis le cache local
  loadCountdown() {
    const savedTime = localStorage.getItem('countdownStartTimeEbook03');
    // console.log(savedTime);
    if (savedTime) {
      const elapsed = Math.floor((Date.now() - parseInt(savedTime)) / 1000);
      this.timeLeft = this.initialTime - elapsed;
      if (this.timeLeft < 0) {
        this.timeLeft = 0; // Prevent negative countdown
      }
    } else {
      this.timeLeft = this.initialTime;
      localStorage.setItem('countdownStartTimeEbook03', Date.now().toString());
    }
    this.startCountdown();
  }

  // Fonction pour démarrer le compte à rebours
  startCountdown() {
    // console.log(`Starting countdown with timeLeft: ${this.timeLeft}`);
    if (this.timeLeft <= 0) {
      this.currentPrice = this.pricePromo01;
      // console.log('No countdown needed, time already elapsed');
      this.showCountdown = false;
      this.cleanupSubscription();
      return; // Arrête le compte à rebours immédiatement si le temps initial est déjà écoulé.
    }

    const timer$ = interval(1000).pipe(
      takeWhile(() => this.timeLeft > 0, true) // inclut la condition pour émettre également lorsque timeLeft atteint 0.
    );

    this.subscription = timer$.subscribe({
      next: () => {
        this.currentPrice = this.pricePromo02;
        this.timeLeft -= 1;
        // console.log(`Counting down: ${this.formatTime(this.timeLeft)}`);
        // console.log(this.formatTime(this.timeLeft));  // Mettre à jour l'affichage à chaque seconde
      },
      complete: () => {
        // console.log('Countdown completed');
        this.currentPrice = this.pricePromo01;
        this.showCountdown = false;
        this.cleanupSubscription();
      }
    });
  }

  // Nettoyage de la subscription pour éviter les fuites de mémoire
  cleanupSubscription() {
    if (this.subscription) {
      this.subscription.unsubscribe();
      this.subscription = null;
    }
  }

  // Fonction pour formater le temps en minutes et secondes
  formatTime(seconds: number): string {
    const minutes: number = Math.floor(seconds / 60);
    const remainingSeconds: number = seconds % 60;
    return `${this.padZero(minutes)} MIN ${this.padZero(remainingSeconds)} SEC`;
  }

  // Fonction pour ajouter un zéro devant un nombre si nécessaire
  padZero(num: number): string {
    return num < 10 ? `0${num}` : `${num}`;  // Assurez-vous que le formatage ajoute un zéro correctement
  }


  // Fonction pour réinitialiser le cache du compte à rebours
  resetCountdownCache() {
    this.showCountdown = true;
    localStorage.removeItem('countdownStartTimeEbook03');  // Supprime uniquement le début du compteur
    this.timeLeft = this.initialTime;               // Réinitialise timeLeft à sa valeur initiale
    localStorage.setItem('countdownStartTimeEbook03', Date.now().toString());  // Réinitialise le temps de départ
    this.startCountdown();                         // Redémarre le compteur
  }






  // ngOnDestroy(): void {
  //   if (this.subscription) {
  //     this.subscription.unsubscribe();
  //   }    
  // }



}
