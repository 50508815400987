<section>
    <div class="fixed-top-container">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="row align-items-center gy-3">
                    <!--Brand men-->
                    <div *ngIf="session.gender.response ==='men'" class="col-12 col-lg-12">
                        <a [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                            routerLink="/business-1/simulateur/etape/2" class="button  button-rounded fw-bolder"><i
                                class="bi-arrow-left-circle-fill"></i></a>
                        <a [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                            routerLink="/business-1/simulateur/etape/2" class="button  button-rounded fw-bolder">3.
                            Financier</a>
                    </div>
                    <!--Brand women-->
                    <div *ngIf="session.gender.response ==='women'" class="col-12 col-lg-12">
                        <a [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                            routerLink="/business-1/simulateur/etape/2" class="button  button-rounded fw-bolder"><i
                                class="bi-arrow-left-circle-fill"></i></a>
                        <a [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                            routerLink="/business-1/simulateur/etape/2" class="button  button-rounded fw-bolder">3.
                            Financier</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<section id="content" class="pt-5">
    <div class="content-wrap py-10">
        <div class="border-bottom py-4">
            <div class="container">
                <div class="text-center mb-5">
                    <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                    <!--Brand men-->
                    <h3 *ngIf="session.gender.response ==='men'" class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        3 / {{session.step3.titleMen}}
                    </h3>
                    <!--Brand women-->
                    <h3 *ngIf="session.gender.response ==='women'" class="display-4 fw-bolder ls1 mb-3 position-relative d-none d-md-block">
                        3 / {{session.step3.titleWomen}}
                    </h3>
                    <!-- Contenu visible uniquement sur les smartphones -->
                    <!--Brand men-->
                    <h6 *ngIf="session.gender.response ==='men'" class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        3 / {{session.step3.titleMen}}
                    </h6>
                    <!--Brand women-->
                    <h6 *ngIf="session.gender.response ==='women'" class="display-6 fw-bolder ls1 mb-3 position-relative d-block d-md-none">
                        3 / {{session.step3.titleWomen}}
                    </h6>
                </div>
                <div class="row align-items-stretch g-4 center mb-5">
                    <!--Tile 01-->
                    <div class="col-md-6 text-center">
                        <!--Brand men-->
                        <div *ngIf="session.gender.response ==='men'" class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [ngStyle]="{
                                'background-color': isCardSelected01 ? session.theme.men.backgroundColor : '#F0F1F2',
                                'border': session.step3.response === '01' ? session.theme.men.borderColor : 'none'
                                }" (click)="navigateNextStep('01',1)">

                                <!-- Texte conditionnel -->
                                <h4 *ngIf="isCardSelected01 === false" class="mb-3 fw-bolder tile-question"
                                    [innerHTML]="session.step3.responses.response01.titleMen">
                                </h4>
                                <h4 *ngIf="isCardSelected01 === true" class="mb-3 fw-bolder tile-question text-white"
                                    [innerHTML]="session.step3.responses.response01.titleMen">
                                </h4>

                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <div class="d-none d-md-block">
                                    <a class="button button-large button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                        [ngClass]="{'hovered': isHovered[0]}" (mouseenter)="onMouseEnter(0)"
                                        (mouseleave)="onMouseLeave(0)">
                                        <i *ngIf="session.step3.response === '01'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <div class="d-block d-md-none">
                                    <a class="button button-mini button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                        [ngClass]="{'hovered': isHovered[0]}" (mouseenter)="onMouseEnter(0)"
                                        (mouseleave)="onMouseLeave(0)">
                                        <i *ngIf="session.step3.response === '01'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--Brand women-->
                        <div *ngIf="session.gender.response ==='women'"
                            class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [ngStyle]="{
                                'background-color': isCardSelected01 ? session.theme.women.backgroundColor : '#F0F1F2',
                                'border': session.step3.response === '01' ? session.theme.women.borderColor : 'none'
                                }" (click)="navigateNextStep('01',1)">

                                <!-- Texte conditionnel -->
                                <h4 *ngIf="isCardSelected01 === false" class="mb-3 fw-bolder tile-question"
                                    [innerHTML]="session.step3.responses.response01.titleWomen">
                                </h4>
                                <h4 *ngIf="isCardSelected01 === true" class="mb-3 fw-bolder tile-question text-white"
                                    [innerHTML]="session.step3.responses.response01.titleWomen">
                                </h4>

                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <div class="d-none d-md-block">
                                    <a class="button button-large button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                        [ngClass]="{'hovered': isHovered[0]}" (mouseenter)="onMouseEnter(0)"
                                        (mouseleave)="onMouseLeave(0)">
                                        <i *ngIf="session.step3.response === '01'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <div class="d-block d-md-none">
                                    <a class="button button-mini button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                        [ngClass]="{'hovered': isHovered[0]}" (mouseenter)="onMouseEnter(0)"
                                        (mouseleave)="onMouseLeave(0)">
                                        <i *ngIf="session.step3.response === '01'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Tile 02-->
                    <div class="col-md-6 text-center">
                        <!--Brand men-->
                        <div *ngIf="session.gender.response ==='men'" class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [ngStyle]="{
                                'background-color': isCardSelected02 ? session.theme.men.backgroundColor : '#F0F1F2',
                                'border': session.step3.response === '02' ? session.theme.men.borderColor : 'none'
                                }" (click)="navigateNextStep('02',2)">

                                <!-- Texte conditionnel -->
                                <h4 *ngIf="isCardSelected02 === false" class="mb-3 fw-bolder tile-question"
                                    [innerHTML]="session.step3.responses.response02.titleMen">
                                </h4>
                                <h4 *ngIf="isCardSelected02 === true" class="mb-3 fw-bolder tile-question text-white"
                                    [innerHTML]="session.step3.responses.response02.titleMen">
                                </h4>

                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <div class="d-none d-md-block">
                                    <a class="button button-large button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                        [ngClass]="{'hovered': isHovered[1]}" (mouseenter)="onMouseEnter(1)"
                                        (mouseleave)="onMouseLeave(1)">
                                        <i *ngIf="session.step3.response === '02'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <div class="d-block d-md-none">
                                    <a class="button button-mini button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                        [ngClass]="{'hovered': isHovered[1]}" (mouseenter)="onMouseEnter(1)"
                                        (mouseleave)="onMouseLeave(1)">
                                        <i *ngIf="session.step3.response === '02'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--Brand women-->
                        <div *ngIf="session.gender.response ==='women'"
                            class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [ngStyle]="{
                                'background-color': isCardSelected02 ? session.theme.women.backgroundColor : '#F0F1F2',
                                'border': session.step3.response === '02' ? session.theme.women.borderColor : 'none'
                                }" (click)="navigateNextStep('02',2)">

                                <!-- Texte conditionnel -->
                                <h4 *ngIf="isCardSelected02 === false" class="mb-3 fw-bolder tile-question"
                                    [innerHTML]="session.step3.responses.response02.titleWomen">
                                </h4>
                                <h4 *ngIf="isCardSelected02 === true" class="mb-3 fw-bolder tile-question text-white"
                                    [innerHTML]="session.step3.responses.response02.titleWomen">
                                </h4>

                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <div class="d-none d-md-block">
                                    <a class="button button-large button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                        [ngClass]="{'hovered': isHovered[1]}" (mouseenter)="onMouseEnter(1)"
                                        (mouseleave)="onMouseLeave(1)">
                                        <i *ngIf="session.step3.response === '02'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <div class="d-block d-md-none">
                                    <a class="button button-mini button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                        [ngClass]="{'hovered': isHovered[1]}" (mouseenter)="onMouseEnter(1)"
                                        (mouseleave)="onMouseLeave(1)">
                                        <i *ngIf="session.step3.response === '02'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Tile 03-->
                    <div class="col-md-6 text-center">
                        <!--Brand men-->
                        <div *ngIf="session.gender.response ==='men'" class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [ngStyle]="{
                                'background-color': isCardSelected03 ? session.theme.men.backgroundColor : '#F0F1F2',
                                'border': session.step3.response === '03' ? session.theme.men.borderColor : 'none'
                                }" (click)="navigateNextStep('03',3)">

                                <!-- Texte conditionnel -->
                                <h4 *ngIf="isCardSelected03 === false" class="mb-3 fw-bolder tile-question"
                                    [innerHTML]="session.step3.responses.response03.titleMen">
                                </h4>
                                <h4 *ngIf="isCardSelected03 === true" class="mb-3 fw-bolder tile-question text-white"
                                    [innerHTML]="session.step3.responses.response03.titleMen">
                                </h4>

                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <div class="d-none d-md-block">
                                    <a class="button button-large button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                        [ngClass]="{'hovered': isHovered[2]}" (mouseenter)="onMouseEnter(2)"
                                        (mouseleave)="onMouseLeave(2)">
                                        <i *ngIf="session.step3.response === '03'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <div class="d-block d-md-none">
                                    <a class="button button-mini button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                        [ngClass]="{'hovered': isHovered[2]}" (mouseenter)="onMouseEnter(2)"
                                        (mouseleave)="onMouseLeave(2)">
                                        <i *ngIf="session.step3.response === '03'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--Brand women-->
                        <div *ngIf="session.gender.response ==='women'"
                            class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [ngStyle]="{
                                'background-color': isCardSelected03 ? session.theme.women.backgroundColor : '#F0F1F2',
                                'border': session.step3.response === '03' ? session.theme.women.borderColor : 'none'
                                }" (click)="navigateNextStep('03',3)">

                                <!-- Texte conditionnel -->
                                <h4 *ngIf="isCardSelected03 === false" class="mb-3 fw-bolder tile-question"
                                    [innerHTML]="session.step3.responses.response03.titleWomen">
                                </h4>
                                <h4 *ngIf="isCardSelected03 === true" class="mb-3 fw-bolder tile-question text-white"
                                    [innerHTML]="session.step3.responses.response03.titleWomen">
                                </h4>

                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <div class="d-none d-md-block">
                                    <a class="button button-large button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                        [ngClass]="{'hovered': isHovered[2]}" (mouseenter)="onMouseEnter(2)"
                                        (mouseleave)="onMouseLeave(2)">
                                        <i *ngIf="session.step3.response === '03'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <div class="d-block d-md-none">
                                    <a class="button button-mini button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                        [ngClass]="{'hovered': isHovered[2]}" (mouseenter)="onMouseEnter(2)"
                                        (mouseleave)="onMouseLeave(2)">
                                        <i *ngIf="session.step3.response === '03'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!--Tile 04-->
                    <div class="col-md-6 text-center">
                        <!--Brand men-->
                        <div *ngIf="session.gender.response ==='men'" class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [ngStyle]="{
                                'background-color': isCardSelected04 ? session.theme.men.backgroundColor : '#F0F1F2',
                                'border': session.step3.response === '04' ? session.theme.men.borderColor : 'none'
                                }" (click)="navigateNextStep('04',4)">

                                <!-- Texte conditionnel -->
                                <h4 *ngIf="isCardSelected04 === false" class="mb-3 fw-bolder tile-question"
                                    [innerHTML]="session.step3.responses.response04.titleMen">
                                </h4>
                                <h4 *ngIf="isCardSelected04 === true" class="mb-3 fw-bolder tile-question text-white"
                                    [innerHTML]="session.step3.responses.response04.titleMen">
                                </h4>

                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <div class="d-none d-md-block">
                                    <a class="button button-large button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                        [ngClass]="{'hovered': isHovered[3]}" (mouseenter)="onMouseEnter(3)"
                                        (mouseleave)="onMouseLeave(3)">
                                        <i *ngIf="session.step3.response === '04'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <div class="d-block d-md-none">
                                    <a class="button button-mini button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.men.backgroundColor, 'color':session.theme.men.textColor }"
                                        [ngClass]="{'hovered': isHovered[3]}" (mouseenter)="onMouseEnter(3)"
                                        (mouseleave)="onMouseLeave(3)">
                                        <i *ngIf="session.step3.response === '04'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                        <!--Brand women-->
                        <div *ngIf="session.gender.response ==='women'"
                            class="card bg-secondary bg-opacity-10 border-0">
                            <div class="card-body p-4 pointer" [ngStyle]="{
                                'background-color': isCardSelected04 ? session.theme.women.backgroundColor : '#F0F1F2',
                                'border': session.step3.response === '04' ? session.theme.women.borderColor : 'none'
                                }" (click)="navigateNextStep('04',4)">

                                <!-- Texte conditionnel -->
                                <h4 *ngIf="isCardSelected04 === false" class="mb-3 fw-bolder tile-question"
                                    [innerHTML]="session.step3.responses.response04.titleWomen">
                                </h4>
                                <h4 *ngIf="isCardSelected04 === true" class="mb-3 fw-bolder tile-question text-white"
                                    [innerHTML]="session.step3.responses.response04.titleWomen">
                                </h4>

                                <!-- Contenu visible uniquement sur les tablettes et les ordinateurs de bureau -->
                                <div class="d-none d-md-block">
                                    <a class="button button-large button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                        [ngClass]="{'hovered': isHovered[3]}" (mouseenter)="onMouseEnter(3)"
                                        (mouseleave)="onMouseLeave(3)">
                                        <i *ngIf="session.step3.response === '04'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                                <!-- Contenu visible uniquement sur les smartphones -->
                                <div class="d-block d-md-none">
                                    <a class="button button-mini button-circle fw-bolder"
                                        [ngStyle]="{'background-color': session.theme.women.backgroundColor, 'color':session.theme.women.textColor }"
                                        [ngClass]="{'hovered': isHovered[3]}" (mouseenter)="onMouseEnter(3)"
                                        (mouseleave)="onMouseLeave(3)">
                                        <i *ngIf="session.step3.response === '04'" class="fa-solid fa-check"></i>
                                        Sélectionner
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="progress">
                    <!-- Brand men -->
                    <div *ngIf="session.gender.response ==='men'"
                        [ngStyle]="{'background-color': session.theme.men.backgroundColor}" class="progress-bar"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width: 27%;">
                    </div>
                    <!-- Brand women -->
                    <div *ngIf="session.gender.response ==='women'"
                        [ngStyle]="{'background-color': session.theme.women.backgroundColor}" class="progress-bar"
                        role="progressbar" aria-valuenow="10" aria-valuemin="0" aria-valuemax="100" style="width: 27%;">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<app-simulator-footer></app-simulator-footer>