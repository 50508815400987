import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Injectable({
  providedIn: 'root'
})
export class SimulatorSessionv1 {

  private simulator = {};


  constructor(private sanitizer: DomSanitizer) {
  }

  /**
     * Initialise la session de simulation en fonction du type spécifié.
     * @param type Le type de simulateur à initialiser (par exemple, 'simulateur-bu-01').
     */
  initializeSimulator(type: string): void {
    if (type === 'simulateur-bu-01') {
      this.simulator = this.createObjectSimulatorBu01();
    }
  }

  /**
  * Crée et retourne l'objet de simulation pour le simulateur 'simulateur-bu-01'.
  * Cet objet contient les informations nécessaires pour configurer et suivre la session utilisateur.
  * @returns L'objet de simulation configuré.
  */
  createObjectSimulatorBu01() {
    let simulateur = {
      stripe:'',
      id: '',
      created: '',
      idsMeta: environment.products.simulatorbu01.id,
      ref: environment.products.simulatorbu01.ref,
      pixel: environment.products.simulatorbu01.pixel,
      price: 0,
      devise: environment.products.simulatorbu01.devise,
      sessionId: 'simulateur-bu-01',
      checkoutCancelUrl: environment.simulatorCheckoutCancelUrl,
      checkoutSuccessUrl: environment.simulatorCheckoutSuccessUrl,
      product: {
        id: environment.products.simulatorbu01.product.id,
        price: environment.products.simulatorbu01.product.price
        //  [
        //     {
        //         amount: 147,
        //         devise: '€',
        //         id: 'price_1PkXzyBTvHG2hcfSR4t0wv8z'
        //     },
        //     {
        //         amount: 27,
        //         devise: '€',
        //         id: 'price_1PkXzyBTvHG2hcfSR4t0wv8z'
        //     },
        //     {
        //         amount: 97,
        //         devise: '€',
        //         id: 'price_1PkY0WBTvHG2hcfS9ykREtWS'
        //     },
        // ]      
      },
      theme: {
        men: {
          backgroundColor: '#2C3E50',
          borderColor: '2px solid #2C3E50',
          textColor: 'white',
          themeTextColor: '#2C3E50'
        },
        women: {
          backgroundColor: '#8E44AD',
          borderColor: '2px solid #8E44AD',
          textColor: 'white',
          themeTextColor: '#8E44AD'
        }
      },
      gender: {
        title: 'Choisissez votre genre',
        response: '',
        check: false,
      },
      // Étape 1 à Étape 10 : Chaque étape contient le titre de la question, la réponse, le point associé et le texte de vente.
      step1: {
        titleMen: 'As-tu déjà une idée précise du business que tu souhaites lancer ?',
        titleWomen: 'As-tu déjà une idée précise du business que tu souhaites lancer ?',
        response: '',
        title_response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Non, je cherche encore' + '<br>' + ' l\'inspiration',
            titleWomen: 'Non, je cherche encore' + '<br>' + ' l\'inspiration',
            title_bis_Men: 'Non, je cherche encore l\'inspiration',
            title_bis_Women: 'Non, je cherche encore l\'inspiration',
            point: 1,
            saleTextMen: 'Il est normal de ne pas avoir encore d\'idée précise. Explore différents secteurs et identifie ce qui te passionne vraiment. Utilise des outils de brainstorming et des ressources comme notre guide pour t\’aider à trouver l\'inspiration.',
            saleTextWomen: 'Il est normal de ne pas avoir encore d\'idée précise. Explore différents secteurs et identifie ce qui te passionne vraiment. Utilise des outils de brainstorming et des ressources comme notre guide pour t\’aider à trouver l\'inspiration.'
          },
          response02: {
            titleMen: 'J\'ai quelques idées mais je ne suis pas encore décidé',
            titleWomen: 'J\'ai quelques idées mais je ne suis pas encore décidée',
            title_bis_Men: 'J\'ai quelques idées mais je ne suis pas encore décidé',
            title_bis_Women: 'J\'ai quelques idées mais je ne suis pas encore décidée',
            point: 2,
            saleTextMen: 'Avoir quelques idées est un très bon début. Il te faut maintenant clarifier et évaluer chaque idée pour déterminer laquelle a le plus de potentiel.',
            saleTextWomen: 'Avoir quelques idées est un très bon début. Il te faut maintenant clarifier et évaluer chaque idée pour déterminer laquelle a le plus de potentiel.'
          },
          response03: {
            titleMen: 'Oui, j\'ai une idée bien définie',
            titleWomen: 'Oui, j\'ai une idée bien définie',
            title_bis_Men: 'Oui, j\'ai une idée bien définie',
            title_bis_Women: 'Oui, j\'ai une idée bien définie',
            point: 3,
            saleTextMen: 'Avec une idée bien définie, tu es déjà sur la bonne voie. Il est temps de transformer cette idée en un business plan solide et à valider ton idée sur le marché.',
            saleTextWomen: 'Avec une idée bien définie, tu es déjà sur la bonne voie. Il est temps de transformer cette idée en un business plan solide et à valider ton idée sur le marché.'
          },
        }
      },
      step2: {
        titleMen: 'Quel type de business envisages-tu de créer ?',
        titleWomen: 'Quel type de business envisages-tu de créer ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Un service physique',
            titleWomen: 'Un service physique',
            title_bis_Men: 'Un service physique',
            title_bis_Women: 'Un service physique',
            point: 1,
            saleTextMen: 'Créer un service physique nécessite de bien comprendre les besoins de ta communauté locale.',
            saleTextWomen: 'Créer un service physique nécessite de bien comprendre les besoins de ta communauté locale.'
          },
          response02: {
            titleMen: 'Un commerce physique',
            titleWomen: 'Un commerce physique',
            title_bis_Men: 'Un commerce physique',
            title_bis_Women: 'Un commerce physique',
            point: 2,
            saleTextMen: 'Ouvrir un commerce physique demande une bonne planification. Tu dois analyser tes concurrents, comprendre ton marché et te différencier avec une offre irrésistible pour attirer des clients locaux.',
            saleTextWomen: 'Ouvrir un commerce physique demande une bonne planification. Tu dois analyser tes concurrents, comprendre ton marché et te différencier avec une offre irrésistible pour attirer des clients locaux.'
          },
          response03: {
            titleMen: 'Un commerce en ligne',
            titleWomen: 'Un commerce en ligne',
            title_bis_Men: 'Un commerce en ligne',
            title_bis_Women: 'Un commerce en ligne',
            point: 3,
            saleTextMen: 'Un commerce en ligne offre une grande flexibilité. Il te faut définir les caractéristiques de tes produits, créer une boutique en ligne, attirer des clients et gérer les aspects logistiques. Notre plan d’action t’aidera à créer et promouvoir tes produits.',
            saleTextWomen: 'Un commerce en ligne offre une grande flexibilité. Il te faut définir les caractéristiques de tes produits, créer une boutique en ligne, attirer des clients et gérer les aspects logistiques. Notre plan d’action t’aidera à créer et promouvoir tes produits.'
          },
          response04: {
            titleMen: 'Un service en ligne',
            titleWomen: 'Un service en ligne',
            title_bis_Men: 'Un service en ligne',
            title_bis_Women: 'Un service en ligne',
            point: 3,
            saleTextMen: 'Les services en ligne sont très demandés. Il te faut définir ton offre, ton élément de différentiation pour attirer des clients et à gérer ton activité en ligne.',
            saleTextWomen: 'Les services en ligne sont très demandés. Il te faut définir ton offre, ton élément de différentiation pour attirer des clients et à gérer ton activité en ligne.'
          },
        }
      },
      step3: {
        titleMen: 'Combien espères-tu gagner chaque mois avec ton entreprise ?',
        titleWomen: 'Combien espères-tu gagner chaque mois avec ton entreprise ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Entre 500 € et 1 000 €',
            titleWomen: 'Entre 500 € et 1 000 €',
            title_bis_Men: '0 à 1.000€ / mois',
            title_bis_Women: '0 à 1.000€ / mois',
            point: 1,
            saleTextMen: 'Avec un objectif de moins de 1 000 € par mois, commence par des projets à faible coût et peu de risques.',
            saleTextWomen: 'Avec un objectif de moins de 1 000 € par mois, commence par des projets à faible coût et peu de risques.'
          },
          response02: {
            titleMen: 'Entre 1 000 € et 5 000 €',
            titleWomen: 'Entre 1 000 € et 5 000 €',
            title_bis_Men: '0 à 5.000€ / mois',
            title_bis_Women: '0 à 5.000€ / mois',
            point: 2,
            saleTextMen: 'Pour gagner entre 1 000 € et 5 000 € par mois, il est crucial de bien cibler ton marché. Il faut également définir et développer des stratégies de marketing efficaces pour augmenter tes revenus.',
            saleTextWomen: 'Pour gagner entre 1 000 € et 5 000 € par mois, il est crucial de bien cibler ton marché. Il faut également définir et développer des stratégies de marketing efficaces pour augmenter tes revenus.'
          },
          response03: {
            titleMen: 'Entre 5 000 € et 10 000 €',
            titleWomen: 'Entre 5 000 € et 10 000 €',
            title_bis_Men: '0 à 10.000€ / mois',
            title_bis_Women: '0 à 10.000€ / mois',
            point: 3,
            saleTextMen: 'Atteindre entre 5 000 € et 10 000 € par mois nécessite une stratégie bien élaborée:  vendre, promouvoir, améliorer tes publicités, diversifier tes offres pour optimiser tes ventes, autant de points cruciaux à maitriser.',
            saleTextWomen: 'Atteindre entre 5 000 € et 10 000 € par mois nécessite une stratégie bien élaborée:  vendre, promouvoir, améliorer tes publicités, diversifier tes offres pour optimiser tes ventes, autant de points cruciaux à maitriser.'
          },
          response04: {
            titleMen: 'Plus de 10 000 €',
            titleWomen: 'Plus de 10 000 €',
            title_bis_Men: '0 à plus de 10.000€ / mois',
            title_bis_Women: '0 à plus de 10.000€ / mois',
            point: 4,
            saleTextMen: 'Avec un objectif de plus de 10 000 € par mois, il est important de penser à grande échelle : scalabilité et automatisation.',
            saleTextWomen: 'Avec un objectif de plus de 10 000 € par mois, il est important de penser à grande échelle : scalabilité et automatisation.'
          },
        }
      },
      step4: {
        titleMen: 'Quelle est ta principale motivation pour démarrer ton entreprise ?',
        titleWomen: 'Quelle est ta principale motivation pour démarrer ton entreprise ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Gagner plus d\'argent',
            titleWomen: 'Gagner plus d\'argent',
            title_bis_Men: 'Gagner plus d\'argent',
            title_bis_Women: 'Gagner plus d\'argent',
            point: 1,
            saleTextMen: 'Si ta principale motivation est de gagner plus d\'argent, il est crucial de comprendre comment monétiser ton idée de manière efficace.',
            saleTextWomen: 'Si ta principale motivation est de gagner plus d\'argent, il est crucial de comprendre comment monétiser ton idée de manière efficace.'
          },
          response02: {
            titleMen: 'Être mon propre patron',
            titleWomen: 'Être ma propre patronne',
            title_bis_Men: 'Être mon propre patron',
            title_bis_Women: 'Être ma propre patronne',
            point: 2,
            saleTextMen: 'Être ton propre patron t’offre une grande liberté. Il te faut donc structurer ton entreprise pour maximiser ton autonomie et ton succès.',
            saleTextWomen: 'Être ton propre patron t’offre une grande liberté. Il te faut donc structurer ton entreprise pour maximiser ton autonomie et ton succès.'
          },
          response03: {
            titleMen: 'Poursuivre ma passion',
            titleWomen: 'Poursuivre ma passion',
            title_bis_Men: 'Poursuivre ma passion',
            title_bis_Women: 'Poursuivre ma passion',
            point: 3,
            saleTextMen: 'Poursuivre ta passion est essentiel pour rester motivé. Comment aligner ton business avec tes passions et  créer une entreprise qui te ressemble ?',
            saleTextWomen: 'Poursuivre ta passion est essentiel pour rester motivé. Comment aligner ton business avec tes passions et  créer une entreprise qui te ressemble ?'
          },
          response04: {
            titleMen: 'Avoir un impact positif sur la société',
            titleWomen: 'Avoir un impact positif sur la société',
            title_bis_Men: 'Avoir un impact positif sur la société',
            title_bis_Women: 'Avoir un impact positif sur la société',
            point: 4,
            saleTextMen: 'Si tu souhaites avoir un impact positif sur la société, il est important de définir des objectifs clairs et mesurables.',
            saleTextWomen: 'Si tu souhaites avoir un impact positif sur la société, il est important de définir des objectifs clairs et mesurables.'
          },
        }
      },
      step5: {
        titleMen: 'Quels défis penses-tu rencontrer dans la création de ton entreprise ?',
        titleWomen: 'Quels défis penses-tu rencontrer dans la création de ton entreprise ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Difficultés à comprendre le marché',
            titleWomen: 'Difficultés à comprendre le marché',
            title_bis_Men: 'Difficultés à comprendre le marché',
            title_bis_Women: 'Difficultés à comprendre le marché',
            point: 1,
            saleTextMen: 'Comprendre le marché peut être complexe, mais essentiel. Utilise notre guide pour apprendre à effectuer des études de marché et à analyser les tendances pour mieux positionner ton entreprise.',
            saleTextWomen: 'Comprendre le marché peut être complexe, mais essentiel. Utilise notre guide pour apprendre à effectuer des études de marché et à analyser les tendances pour mieux positionner ton entreprise.'
          },
          response02: {
            titleMen: 'Manque de connaissances ou de compétences',
            titleWomen: 'Manque de connaissances ou de compétences',
            title_bis_Men: 'Manque de connaissances ou de compétences',
            title_bis_Women: 'Manque de connaissances ou de compétences',
            point: 2,
            saleTextMen: 'Le manque de connaissances peut être un obstacle, mais il est surmontable. Notre guide inclut des ressources pour t’ aider à acquérir les compétences nécessaires notamment avec l’IA.',
            saleTextWomen: 'Le manque de connaissances peut être un obstacle, mais il est surmontable. Notre guide inclut des ressources pour t’ aider à acquérir les compétences nécessaires notamment avec l’IA.'
          },
          response03: {
            titleMen: 'Manque de fonds',
            titleWomen: 'Manque de fonds',
            title_bis_Men: 'Manque de fonds',
            title_bis_Women: 'Manque de fonds',
            point: 3,
            saleTextMen: 'Le manque de fonds est un défi courant. C’est pourquoi il te faut un plan clair pour comprendre et gérer au mieux tes ressources.',
            saleTextWomen: 'Le manque de fonds est un défi courant. C’est pourquoi il te faut un plan clair pour comprendre et gérer au mieux tes ressources.'
          },
          response04: {
            titleMen: 'Problèmes de gestion du temps',
            titleWomen: 'Problèmes de gestion du temps',
            title_bis_Men: 'Problèmes de gestion du temps',
            title_bis_Women: 'Problèmes de gestion du temps',
            point: 4,
            saleTextMen: 'La gestion du temps est cruciale pour le succès. Être organisé, définir les tâches même les plus minimes , t’ aidera à être plus productif.',
            saleTextWomen: 'La gestion du temps est cruciale pour le succès. Être organisé, définir les tâches même les plus minimes , t’ aidera à être plus productif.'
          },
        }
      },
      step6: {
        titleMen: 'Combien de temps es-tu prêt à consacrer à ton entreprise chaque semaine ?',
        titleWomen: 'Combien de temps es-tu prête à consacrer à ton entreprise chaque semaine ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Moins de 10 heures',
            titleWomen: 'Moins de 10 heures',
            title_bis_Men: 'Moins de 10 heures',
            title_bis_Women: 'Moins de 10 heures',
            point: 1,
            saleTextMen: 'Avec moins de 10 heures par semaine à consacrer à ton entreprise, il est important de prioriser tes tâches et d\'utiliser des outils d\'automatisation.',
            saleTextWomen: 'Avec moins de 10 heures par semaine à consacrer à ton entreprise, il est important de prioriser tes tâches et d\'utiliser des outils d\'automatisation.'
          },
          response02: {
            titleMen: 'Entre 10 et 20 heures',
            titleWomen: 'Entre 10 et 20 heures',
            title_bis_Men: 'Entre 10 et 20 heures',
            title_bis_Women: 'Entre 10 et 20 heures',
            point: 2,
            saleTextMen: 'Avec 10 à 20 heures par semaine, tu peux faire des progrès significatifs. Tout réside dans l’organisation.',
            saleTextWomen: 'Avec 10 à 20 heures par semaine, tu peux faire des progrès significatifs. Tout réside dans l’organisation.'
          },
          response03: {
            titleMen: 'Entre 20 et 40 heures',
            titleWomen: 'Entre 20 et 40 heures',
            title_bis_Men: 'Entre 20 et 40 heures',
            title_bis_Women: 'Entre 20 et 40 heures',
            point: 3,
            saleTextMen: 'Avec 20 à 40 heures par semaine, tu peux te consacrer pleinement à ton projet. Il te faut structurer votre semaine, allier nutrition et sport car oui, le travail ne fait pas tout !',
            saleTextWomen: 'Avec 20 à 40 heures par semaine, tu peux te consacrer pleinement à ton projet. Il te faut structurer votre semaine, allier nutrition et sport car oui, le travail ne fait pas tout !'
          },
          response04: {
            titleMen: 'Plus de 40 heures',
            titleWomen: 'Plus de 40 heures',
            title_bis_Men: 'Plus de 40 heures',
            title_bis_Women: 'Plus de 40 heures',
            point: 4,
            saleTextMen: 'Plus de 40 heures par semaine montre un engagement fort. Eviter l\'épuisement professionnel en équilibrant travail et vie personnelle est primordiale.',
            saleTextWomen: 'Plus de 40 heures par semaine montre un engagement fort. Eviter l\'épuisement professionnel en équilibrant travail et vie personnelle est primordiale.'
          },
        }
      },
      step7: {
        titleMen: 'Es-tu prêt à investir dans des formations pour développer tes compétences entrepreneuriales ?',
        titleWomen: 'Es-tu prête à investir dans des formations pour développer tes compétences entrepreneuriales ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Non, je préfère apprendre par moi-même',
            titleWomen: 'Non, je préfère apprendre par moi-même',
            title_bis_Men: 'Non, je préfère apprendre par moi-même',
            title_bis_Women: 'Non, je préfère apprendre par moi-même',
            point: 1,
            saleTextMen: 'L\'apprentissage autodidacte est précieux, mais investir dans des formations peut accélérer ton succès.',
            saleTextWomen: 'L\'apprentissage autodidacte est précieux, mais investir dans des formations peut accélérer ton succès.'
          },
          response02: {
            titleMen: 'Peut-être, si cela s\'avère nécessaire',
            titleWomen: 'Peut-être, si cela s\'avère nécessaire',
            title_bis_Men: 'Peut-être, si cela s\'avère nécessaire',
            title_bis_Women: 'Peut-être, si cela s\'avère nécessaire',
            point: 2,
            saleTextMen: 'Investir dans des formations peut être bénéfique, il n’est pas nécessaire de dépenser des fortunes pour apprendre !',
            saleTextWomen: 'Investir dans des formations peut être bénéfique, il n’est pas nécessaire de dépenser des fortunes pour apprendre !'
          },                    
          response03: {
            titleMen: 'Oui, absolument',
            titleWomen: 'Oui, absolument',
            title_bis_Men: 'Oui, absolument',
            title_bis_Women: 'Oui, absolument',
            point: 3,
            saleTextMen: 'Investir dans des formations est un excellent choix. Il te faut définir ton profil de compétences afin de savoir vers où te diriger.',
            saleTextWomen: 'Investir dans des formations est un excellent choix. Il te faut définir ton profil de compétences afin de savoir vers où te diriger.'
          },
        }
      },
      step8: {
        titleMen: 'Avec combien de personnes souhaites-tu travailler dans ton entreprise ?',
        titleWomen: 'Avec combien de personnes souhaites-tu travailler dans ton entreprise ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Seul',
            titleWomen: 'Seule',
            title_bis_Men: 'Seul',
            title_bis_Women: 'Seule',
            point: 1,
            saleTextMen: 'Travailler seul a ses avantages, mais peut être limitant. Un conseil, pense au Freelance sur des missions courtes et précises lorsque tu flanches sur un élément',
            saleTextWomen: 'Travailler seul a ses avantages, mais peut être limitant. Un conseil, pense au Freelance sur des missions courtes et précises lorsque tu flanches sur un élément'
          },
          response02: {
            titleMen: 'Avec un/une associé(e)',
            titleWomen: 'Avec un/une associé(e)',
            title_bis_Men: 'Avec un/une associé(e)',
            title_bis_Women: 'Avec un/une associé(e)',
            point: 2,
            saleTextMen: 'Conseil : mettez-vous d’accord dès le début, avant même de générer tes premiers euros afin d’éviter toute ambiguïté pour le futur ! Chacun doit avoir son rôle pour ne pas se marcher dessus.',
            saleTextWomen: 'Conseil : mettez-vous d’accord dès le début, avant même de générer tes premiers euros afin d’éviter toute ambiguïté pour le futur ! Chacun doit avoir son rôle pour ne pas se marcher dessus.'
          },
          response03: {
            titleMen: 'Plus de 3 / équipe',
            titleWomen: 'Plus de 3 / équipe',
            title_bis_Men: 'Plus de 3 / équipe',
            title_bis_Women: 'Plus de 3 / équipe',
            point: 3,
            saleTextMen: 'Travailler avec une petite équipe nécessite une bonne gestion de temps et d’argent. Cela demande des compétences en leadership et management. ',
            saleTextWomen: 'Travailler avec une petite équipe nécessite une bonne gestion de temps et d’argent. Cela demande des compétences en leadership et management. '
          },
        }
      },
      step9: {
        titleMen: 'Quel niveau de risque es-tu prêt à prendre dans ton entreprise ?',
        titleWomen: 'Quel niveau de risque es-tu prête à prendre dans ton entreprise ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Très faible – Je ne veux prendre aucun risque financier',
            titleWomen: 'Très faible – Je ne veux prendre aucun risque financier',
            title_bis_Men: 'Très faible – Je ne veux prendre aucun risque financier',
            title_bis_Women: 'Très faible – Je ne veux prendre aucun risque financier',
            point: 1,
            saleTextMen: 'Lorsque l’on lance un business, il est nécessaire d’investir une somme de départ, minime soit-elle, que ce soit pour payer les produits à vendre et ou pour utiliser des outils d’aide à la création de ton business. Le tout est de bien pondérer son budget pour pouvoir donner une chance à son projet.',
            saleTextWomen: 'Lorsque l’on lance un business, il est nécessaire d’investir une somme de départ, minime soit-elle, que ce soit pour payer les produits à vendre et ou pour utiliser des outils d’aide à la création de ton business. Le tout est de bien pondérer son budget pour pouvoir donner une chance à son projet.'
          },
          response02: {
            titleMen: 'Faible – Je préfère la sécurité et les petits gains sûrs',
            titleWomen: 'Faible – Je préfère la sécurité et les petits gains sûrs',
            title_bis_Men: 'Faible – Je préfère la sécurité et les petits gains sûrs',
            title_bis_Women: 'Faible – Je préfère la sécurité et les petits gains sûrs',
            point: 2,
            saleTextMen: 'Lorsque l’on lance un business, il est nécessaire d’investir une somme de départ, minime soit-elle, que ce soit pour payer les produits à vendre et ou pour utiliser des outils d’aide à la création de ton business. Le tout est de bien pondérer son budget pour pouvoir donner une chance à son projet.',
            saleTextWomen: 'Lorsque l’on lance un business, il est nécessaire d’investir une somme de départ, minime soit-elle, que ce soit pour payer les produits à vendre et ou pour utiliser des outils d’aide à la création de ton business. Le tout est de bien pondérer son budget pour pouvoir donner une chance à son projet.'
          },
          response03: {
            titleMen: 'Modéré – Je prends des risques mais de façon calculée',
            titleWomen: 'Modéré – Je prends des risques mais de façon calculée',
            title_bis_Men: 'Modéré – Je prends des risques mais de façon calculée',
            title_bis_Women: 'Modéré – Je prends des risques mais de façon calculée',
            point: 3,
            saleTextMen: 'Prendre des risques calculés est une bonne approche.  Il est nécessaire d’investir une somme de départ, minime soit-elle, lors de la création de son business. Le tout est de bien pondérer son budget pour pouvoir donner une chance à son projet.',
            saleTextWomen: 'Prendre des risques calculés est une bonne approche.  Il est nécessaire d’investir une somme de départ, minime soit-elle, lors de la création de son business. Le tout est de bien pondérer son budget pour pouvoir donner une chance à son projet.'
          },
          response04: {
            titleMen: 'Très élevé – Je suis prêt à prendre de grands risques pour de grands gains',
            titleWomen: 'Très élevé – Je suis prête à prendre de grands risques pour de grands gains',
            title_bis_Men: 'Très élevé – Je suis prêt à prendre de grands risques pour de grands gains',
            title_bis_Women: 'Très élevé – Je suis prête à prendre de grands risques pour de grands gains',
            point: 4,
            saleTextMen: 'Prendre de grands risques peut mener à de grandes récompenses. Toutefois attention, il est préférable d’avoir toutes les clés en main afin de ne pas truquer la partie !',
            saleTextWomen: 'Prendre de grands risques peut mener à de grandes récompenses. Toutefois attention, il est préférable d’avoir toutes les clés en main afin de ne pas truquer la partie !'
          },
        }
      },
      step10: {
        titleMen: 'Quel niveau de risque es-tu prêt à prendre dans ton entreprise ?',
        titleWomen: 'Quel niveau de risque es-tu prêt à prendre dans ton entreprise ?',
        response: '',
        check: false,
        point: 0,
        responses: {
          response01: {
            titleMen: 'Très faible – Je ne veux prendre aucun risque financier',
            titleWomen: 'Très faible – Je ne veux prendre aucun risque financier',
            title_bis_Men: 'Très faible – Je ne veux prendre aucun risque financier',
            title_bis_Women: 'Très faible – Je ne veux prendre aucun risque financier',
            point: 1,
            saleTextMen: 'Des options sûres et des investissements à faible risque qui garantissent une croissance stable.',
            saleTextWomen: 'Des options sûres et des investissements à faible risque qui garantissent une croissance stable.'
          },
          response02: {
            titleMen: 'Faible – Je préfère la sécurité et les petits gains sûrs',
            titleWomen: 'Faible – Je préfère la sécurité et les petits gains sûrs',
            title_bis_Men: 'Faible – Je préfère la sécurité et les petits gains sûrs',
            title_bis_Women: 'Faible – Je préfère la sécurité et les petits gains sûrs',
            point: 2,
            saleTextMen: 'Découvrez des stratégies conservatrices qui offrent une sécurité maximale avec un retour régulier.',
            saleTextWomen: 'Découvrez des stratégies conservatrices qui offrent une sécurité maximale avec un retour régulier.'
          },
          response03: {
            titleMen: 'Modéré – Je prends des risques mais de façon calculée',
            titleWomen: 'Modéré – Je prends des risques mais de façon calculée',
            title_bis_Men: 'Modéré – Je prends des risques mais de façon calculée',
            title_bis_Women: 'Modéré – Je prends des risques mais de façon calculée',
            point: 3,
            saleTextMen: 'Apprenez à évaluer et à gérer les risques pour maximiser vos retours tout en contrôlant votre exposition.',
            saleTextWomen: 'Apprenez à évaluer et à gérer les risques pour maximiser vos retours tout en contrôlant votre exposition.'
          },
          response04: {
            titleMen: 'Très élevé – Je suis prêt à prendre de grands risques pour de grands gains',
            titleWomen: 'Très élevé – Je suis prêt à prendre de grands risques pour de grands gains',
            title_bis_Men: 'Très élevé – Je suis prêt à prendre de grands risques pour de grands gains',
            title_bis_Women: 'Très élevé – Je suis prêt à prendre de grands risques pour de grands gains',
            point: 4,
            saleTextMen: 'Stratégies pour les entrepreneurs audacieux, avec des conseils pour naviguer dans les situations à haut risque tout en visant des récompenses substantielles.',
            saleTextWomen: 'Stratégies pour les entrepreneurs audacieux, avec des conseils pour naviguer dans les situations à haut risque tout en visant des récompenses substantielles.'
          },
        }
      },
      stepEmail: {
        title: 'Une adresse email',
        response: '',
        check: false,
      }
    }
    return simulateur;
  }

  /**
   * Sécurise le texte HTML en utilisant DomSanitizer.
   * @param text Le texte à sécuriser.
   * @returns Le texte sécurisé sous forme de SafeHtml.
   */
  sanitize(text: string): SafeHtml {

    const rawTitle = 'Non, je cherche encore<br> l\'inspiration';

    let a = this.sanitizer.bypassSecurityTrustHtml(rawTitle);

    console.log(a); // Vérification du texte sécurisé

    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  createObjectSimulatorBu02() {
    let simulateur = {
      id: '',
      created: '',
      checkoutCancelUrl: environment.simulatorCheckoutCancelUrl,
      checkoutSuccessUrl: environment.simulatorCheckoutSuccessUrl,
      price: '',
      step1: {
        title: 'Votre Business est null ?',
        response: '',
        check: false,
        next: ''
      },
    }

    return simulateur;

  }

  /**
    * Charge l'état du simulateur depuis le sessionStorage si disponible.
    * @param idSession L'ID de la session à charger.
    */
  loadState(idSession: any): void {
    const savedState = this.getItem(idSession);
    if (savedState) {
      this.simulator = savedState;
    }
  }

  /**
     * Sauvegarde l'état actuel du simulateur dans le sessionStorage.
     * @param idSession L'ID de la session à sauvegarder.
     */
  saveState(idSession: any): void {
    this.setItem(idSession, this.simulator);

  }

  /**
 * Enregistre un élément dans le sessionStorage.
 * @param key La clé sous laquelle enregistrer l'élément.
 * @param value La valeur à enregistrer.
 */
  setItem(key: string, value: any): void {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  /**
  * Récupère un élément depuis le sessionStorage.
  * @param key La clé de l'élément à récupérer.
  * @returns L'élément récupéré ou null s'il n'existe pas.
  */
  getItem(key: string): any {
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }

  /**
  * Récupère un élément depuis le sessionStorage.
  * @param key La clé de l'élément à récupérer.
  * @returns L'élément récupéré ou null s'il n'existe pas.
  */
  removeItem(key: string): void {
    sessionStorage.removeItem(key);
  }

  /**
   * Vide complètement le sessionStorage.
   */
  clearSession(): void {
    sessionStorage.clear();
  }

}